import { useCallback, useEffect, useRef, useState } from 'react';
import { BreadcrumbsProps } from '@material-ui/core';
import { BreadcrumbsList } from './components';

export interface SBreadcrumbProps<T> extends BreadcrumbsProps {
  items: Array<T>;
  fontWeight?: number;
  fontSize?: number;
  disableDefaultExpand?: boolean;
  noWrap?: boolean;
  parseBreadcrumb: (breadcrumb: T) => string;
  onBreadcrumbClick?: (breadcrumb: T) => void;
}

const SBreadcrumbs = <T,>({
  items,
  disableDefaultExpand = false,
  maxItems = 2,
  noWrap,
  ...rest
}: SBreadcrumbProps<T>) => {
  const [expand, setExpand] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleExpandClick = useCallback(() => setExpand(true), []);

  // This useEffect is used to add event listener to the expand icon to set the expand state to true and show all breadcrumbs
  useEffect(() => {
    if (ref.current && !disableDefaultExpand) {
      const expandIcon = ref.current.querySelector('[aria-label="Show path"]');

      if (expandIcon) {
        return expandIcon.addEventListener('click', handleExpandClick);
      }

      return () => {
        if (expandIcon) {
          expandIcon.removeEventListener('click', handleExpandClick);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is used to check if the breadcrumbs are overflowing and set the expand state accordingly
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const element = ref.current!;
    if (!element) return;

    const isOverflow = element.scrollHeight > element.clientHeight;
    setExpand(!isOverflow);
  }, [ref, items]);

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        height: expand ? 'auto' : 20,
        overflow: 'hidden'
      }}>
      {expand ? (
        <BreadcrumbsList<T>
          items={items}
          maxItems={items.length}
          noWrap={expand && noWrap}
          {...rest}
        />
      ) : (
        <BreadcrumbsList<T>
          items={items}
          maxItems={maxItems}
          noWrap={noWrap}
          {...rest}
        />
      )}
    </div>
  );
};

export default SBreadcrumbs;
