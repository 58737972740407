import { AxiosMethods, AutoRFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import { RfqFilters } from './Types';

export enum AutoRFQQueryKey {
  GET_ALL = 'GET_ALL_AUTO_RFQ',
  GENERATE_CSV = 'GENERATE_CSV_AUTO_RFQ'
}

export const getRfqsQuery = (params: RfqFilters) => ({
  queryKey: [AutoRFQQueryKey.GET_ALL, params],
  queryFn: () =>
    axiosHelper({
      endpoint: AutoRFQApi.GetAll,
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_API,
      params
    })
});

export const generateRfqCSVQuery = (id: string | number) => ({
  queryKey: [AutoRFQQueryKey.GENERATE_CSV, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AutoRFQApi.GenerateCSV.replace(':id', id.toString()),
      method: AxiosMethods.GET,
      baseURL: process.env.REVOPS_API,
      responseType: 'blob', // Treat response as a binary file
      headers: {
        Accept: 'text/csv' // Expect CSV response
      }
    })
});
