import { useQuery } from '@tanstack/react-query';
import { getBreadcrumbsQuery } from '../../../../services';

interface useBreadcrumbProps {
  categoryId?: string | number;
  enabled?: boolean;
}

export const useBreadcrumb = ({
  categoryId,
  enabled = true
}: useBreadcrumbProps) => {
  const {
    data: breadcrumbs,
    isFetching: loading,
    isFetched: fetched
  } = useQuery({
    ...getBreadcrumbsQuery(categoryId),
    enabled
  });

  return { breadcrumbs: breadcrumbs || [], loading, fetched };
};
