import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { NylasAPIVersion } from '@setvi/shared/services/react-query/query/user/types';
import { useNylas } from 'Hooks/useNylas';
import {
  getNylasAccountStatus,
  connectNylasAccount,
  disconnectNylasAccount
} from 'Services';
import { NylasStatus } from 'Services/Mutations/User/Types';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useSnackbar } from 'notistack';
import { ROUTES } from 'enumsV2';

export const useEmailServerV3 = () => {
  const { nylasToken, user, companyData, setUser } = useAppContext();
  const [nylasInvalid, setNylasInvalid] = useState(false);
  const [connected, setConnected] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [nylasUserEmail, setNylasUserEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: getNylasStatus, isLoading: nylasStatusLoading } =
    useMutation(getNylasAccountStatus());
  const { mutateAsync: connectNylas, isLoading: nylasConnectLoading } =
    useMutation(connectNylasAccount());
  const { mutateAsync: disconnectNylas, isLoading: nylasDisconnectLoading } =
    useMutation(disconnectNylasAccount());
  const { connectV3 } = useNylas(nylasToken);
  const navigate = useNavigate();

  const disconnect = useCallback(() => {
    try {
      disconnectNylas();
      setNylasUserEmail('');
      setConnected(false);
      setUser({ ...user, NylasGrantID: '' });
    } catch {
      enqueueSnackbar('Error occured while disconnecting email server', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnectNylas, enqueueSnackbar, user]);

  const initiateNylasConnection = useCallback(() => {
    connectV3(companyData?.EmailSyncCredentials?.ClientID_V3);
  }, [companyData, connectV3]);

  const getStatus = useCallback(async () => {
    const data = await getNylasStatus();
    if (!data?.Data) return;

    setNylasInvalid(
      data.Data.ApiVersion === NylasAPIVersion.v2 &&
        data.Data.Status === NylasStatus.Running
    );
    setConnected(data.Data?.Status === NylasStatus.Valid);
    setShowDisconnect(
      user.NylasGrantID && data.Data.ApiVersion === NylasAPIVersion.v3
    );
    setNylasUserEmail(data.Data?.Email);
  }, [getNylasStatus, user]);

  const connect = useCallback(
    async (Code: string, RedirectUri: string) => {
      try {
        const data = await connectNylas({ Code, RedirectUri });
        setConnected(data.Data?.Status === NylasStatus.Valid);
        setNylasUserEmail(data.Data?.Email);

        navigate(ROUTES.SETTINGS_INTEGRATIONS, { replace: true });
        navigate(0);
      } catch {
        enqueueSnackbar('Error occured while connecting email server', {
          variant: 'error'
        });
      }
    },
    [connectNylas, enqueueSnackbar, navigate]
  );

  useEffect(() => {
    if (user.NylasGrantID) getStatus();
  }, [user.NylasGrantID, getStatus]);

  // Support for transitionary period of new nylas integration
  // Users on v3 which do not have a valid nylas account status will be considered invalid
  // Users which transition from v2 to v3 will be considered invalid if they have
  // EmailSyncAccessToken(v2) or EmailSyncAccountID(v2) but not NylasGrantID (v3)
  // V3 will support only valid and invalid status so the logic can be removed after transition of all users
  // Invalid nylas users should be given option to recconect or disconnect
  const isNylasInvalid =
    nylasInvalid ||
    (!user.NylasGrantID &&
      (user.EmailSyncAccessToken || user.EmailSyncAccountID));

  return {
    initiateNylasConnection,
    connect,
    disconnect,
    isNylasInvalid,
    connected,
    nylasUserEmail,
    showDisconnect,
    isLoading:
      nylasStatusLoading || nylasConnectLoading || nylasDisconnectLoading
  };
};
