export enum ResourceApi {
  GetFavorite = '/api/v1.0/resource/favorite',
  GetFeatured = '/api/v1.0/resource/featured',
  GetPopular = '/WebApi/GetPopularResources',
  GetRecentlyUploaded = '/api/v1.0/resource/whatsnew/paged',
  AddToFavorites = '/WebAPI/AddToFavorites',
  RemoveFromFavorites = '/WebAPI/DeleteFavorite',
  GetShareableLink = '/WebAPI/GetLink',
  GetCategoriesAndResources = '/WebAPI/GetCategoriesAndResources',
  GetUserResources = '/api/v1.0/resource/user/:categoryId',
  GetCompanyResources = '/api/v1.0/resource/company/:categoryId',
  CreateResource = '/WebAPI/UploadUserResource',
  CreateWebLinkResource = '/api/v1.0/resource/createweblink',
  GetResource = '/api/v1.0/resource/:resourceId',
  GetChildResources = 'webapi/GetChildResources',
  GetResourceBookmarks = 'webapi/GetResourceBookmarks',
  SaveResourceBookmarks = 'webApi/SaveResourceBookmarks',
  DeleteUserResource = '/WebAPI/deleteuserresource',
  ProductAssociatedResources = '/api/v1.0/resource/akeneoProduct'
}

export enum AdminResourceApi {
  GetResources = '/api/v2/admin/ResourceCategory/:id/resources',
  GetResource = '/api/v2/admin/Resources/:id',
  GetCategoryResources = '/api/v2/admin/Resources/:id/getCompanyResources',
  GetCategoryGroups = '/api/v2/admin/Groups/getGroupsFromResourceCategories',
  ChangeResourcesCategory = 'api/v2/admin/Resources/:sourceCategoryID/changeCategory',
  ChangeResourceStatus = '/api/v2/admin/Resources/:resourceId/changestatus/:statusId',
  BatchChangeResourceStatus = '/api/v2/admin/Resources/batchChangeStatus/:statusId',
  DeleteResource = '/api/v2/admin/Resources/:id',
  BatchDeleteResources = '/api/v2/admin/Resources/batchDelete',
  ResourceTags = '/api/v2/admin/tags',
  UpdateResource = '/api/v2/admin/Resources/update',
  CreateResource = '/api/v2/admin/Resources/create',
  GetResourceProcessStatus = '/api/v2/admin/Resources/resourceProcessingStatus',
  ResourceUploadFinished = '/api/v2/admin/Resources/uploadFinish',
  ChangeResourceThumbnail = '/api/v2/admin/Resources/:id/changeThumbnail',
  UpdateResourceCategories = '/api/v2/admin/Resources/updateCategoryMembership',
  UpdateResourcesIsShareable = '/api/v2/admin/Resources/batchIsShareable',
  GetCategoryAdminResources = '/api/v2/admin/ResourceCategory/:id/categoryResources',
  GetFeaturedResources = '/api/v2/admin/Resources/getFeaturedResources',
  RemoveFeaturedResource = '/api/v2/admin/Resources/:id/featured',
  AddFeaturedResources = '/api/v2/admin/Resources/featured',
  GetFeaturedResourcesGroups = '/api/v2/admin/Groups/getGroupsFromFeaturedResources',
  GetFeaturedResource = '/api/v2/admin/Resources/:id/featured',
  EditFeaturedResource = '/api/v2/admin/Resources/featured',
  ChangeFeaturedResourcesOrder = 'api/v2/admin/Resources/featuredChangeOrder'
}
