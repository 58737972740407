import { Box, makeStyles, Paper } from '@material-ui/core';
import { SButton } from '@setvi/shared/components';
import { Resource } from '@setvi/shared/interfaces';
import BackButton from '../BackButton';
import ResultsContent from '../ResultsContent';
import ResultsHeader from '../ResultsHeader';

const useSearchStyles = makeStyles(theme => ({
  paperRoot: {
    padding: `${theme.spacing(4)}px 0px`,
    width: '100%',
    position: 'absolute',
    top: '45px',
    boxSizing: 'border-box'
  },
  button: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px 0px`
  }
}));

interface ISearchResults {
  resources: Resource[];
  searchQuery: string;
  isLoading: boolean;
  // When user manually triggers search inside documents
  isUserSearchingInsideDocuments: boolean;
  onBackToQuickSearch: (query: string) => void;
  // When search inside documents is automatically triggered
  isResultInsideDocuments: boolean;
  onSearchInsideDocuments: (query: string) => void;
  handleSearchClose: () => void;
}

const SearchResults = ({
  resources,
  searchQuery,
  isLoading,
  isUserSearchingInsideDocuments,
  onBackToQuickSearch,
  isResultInsideDocuments,
  onSearchInsideDocuments,
  handleSearchClose
}: ISearchResults) => {
  const styles = useSearchStyles();

  return (
    <Paper elevation={6} className={styles.paperRoot}>
      {isUserSearchingInsideDocuments && !isLoading && (
        <BackButton onClick={() => onBackToQuickSearch(searchQuery)} />
      )}
      {resources.length > 0 && !isLoading && (
        <ResultsHeader totalResults={resources.length} />
      )}
      <ResultsContent
        handleSearchClose={handleSearchClose}
        isLoading={isLoading}
        results={resources}
        searchQuery={searchQuery}
      />
      {!isResultInsideDocuments && (
        <Box className={styles.button}>
          <SButton
            fullWidth
            onClick={() => onSearchInsideDocuments(searchQuery)}>
            Search Inside Documents
          </SButton>
        </Box>
      )}
    </Paper>
  );
};

export default SearchResults;
