import { makeStyles, Theme } from '@material-ui/core';
import { useMemo } from 'react';
import SText, { STextProps } from '../sui/stext';

const useStyles = makeStyles<
  Theme,
  { highlightColor: string; highlightWeight: number }
>(({ palette }) => ({
  highlight: {
    fontWeight: ({ highlightWeight }) => highlightWeight || 400,
    color: ({ highlightColor }) => highlightColor || palette.primary.main
  }
}));

interface SHighlightText extends STextProps {
  searchQuery: string;
  highlightColor?: string;
  highlightWeight?: number;
  value: string;
}

const SHighlightText = ({
  searchQuery = '',
  highlightColor,
  highlightWeight,
  value,
  ...rest
}: SHighlightText) => {
  const classes = useStyles({ highlightColor, highlightWeight });

  const markText = useMemo(() => {
    if (!value) return '-';
    if (searchQuery === '') return value;

    // this function is used to enable searching for special symbols
    const escapeRegExp = (string: string) =>
      string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const strArr = value?.split(
      new RegExp(`(${escapeRegExp(searchQuery)})`, 'ig')
    );

    return strArr.map((i, index) => {
      if (i === '') return null;
      if (i?.toLowerCase() === searchQuery?.toLowerCase()) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`match${index}`} className={classes.highlight}>
            {i}
          </span>
        );
      }
      return i;
    });
  }, [classes.highlight, searchQuery, value]);

  return (
    <SText title={value} {...rest}>
      {markText}
    </SText>
  );
};

export default SHighlightText;
