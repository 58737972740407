import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { enqueueSnackbar } from 'notistack';

import SLabel from '@setvi/shared/components/sui/slabel';

import SText from '../sui/stext';
import { useFilestack } from '../../hooks';
import { ButtonContainer, ContentContainer } from './style';
import { downloadResource } from '../../utils';
import { SImageUploadProps } from './interfaces';
import {
  Loading,
  Placeholder,
  Content,
  DownloadButton,
  RemoveButton
} from './components';

enum ErrorCodes {
  LARGE_FILE = 'file-too-large'
}

const SImageUpload = ({
  tags,
  name,
  value,
  label = '',
  required = false,
  loading: loadingProp = false,
  resolution,
  downloadUrl,
  hideActions = false,
  errorImage,
  maxFileSize,
  customPlaceholder,
  placeholderOptions,

  onChange
}: SImageUploadProps) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(value || null);

  useEffect(() => {
    if (value !== image) setImage(value || null);
  }, [image, value]);

  const { fileStackUpload } = useFilestack();

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    ...(maxFileSize ? { maxSize: maxFileSize * 1024 * 1024 } : {}),
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
      'image/gif': []
    },
    onDropAccepted: async files => {
      setLoading(true);

      const currentFile = files[0];

      const getProgress = (total: number) => {
        setProgress(total);
      };

      const { url, handle } = await fileStackUpload({
        file: currentFile,
        tags,
        getProgress
      });

      if (url && handle) {
        setProgress(100);

        // Some times file is small and there is no time to update progress so l added delay
        setTimeout(() => {
          setProgress(0);
          setImage(url);
          setLoading(false);
          onChange({ name, url, id: handle });
        }, 400);
      }
    },
    onDropRejected: files => {
      const errorCode = files[0].errors[0].code;
      const errorMsg =
        files?.[0]?.errors?.[0]?.message || 'Invalid file format';
      const message =
        errorCode === ErrorCodes.LARGE_FILE
          ? `File is larger than ${maxFileSize} MegaBytes`
          : errorMsg;

      enqueueSnackbar(message, { variant: 'error' });
    }
  });

  return (
    <Box width="100%" height="100%">
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between">
        <SLabel
          title={
            <Box flex={1} display="flex" alignItems="center" gridGap={4}>
              <SText weight="bold" size="sm">
                {label}
              </SText>
              <SText weight="bold" size="sm" fontColor="#696974">
                {resolution}
              </SText>
            </Box>
          }
          required={required}
          variant="body2"
        />

        {!!image && !hideActions && (
          <ButtonContainer>
            <DownloadButton
              onClick={() => {
                if (downloadUrl) downloadResource(downloadUrl, 'download.jpg');
              }}
            />
            <RemoveButton
              onClick={() => {
                setImage(null);
                onChange({ name, url: '', id: '' });
              }}
            />
          </ButtonContainer>
        )}
      </Box>

      <ContentContainer
        border={`2px dashed ${!loading && !image ? '#E6E7E9' : '#50B5FF'}`}
        {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Loading
          loading={loading}
          progress={progress}
          initLoading={loadingProp}
        />
        {!loading &&
          !loadingProp &&
          !image &&
          (customPlaceholder || (
            <Placeholder {...placeholderOptions} open={open} />
          ))}
        <Content
          description={placeholderOptions?.subtitle}
          errorImage={errorImage}
          loading={loading || loadingProp}
          image={image}
          open={open}
        />
      </ContentContainer>
    </Box>
  );
};

export default SImageUpload;
