export enum COMPANIES {
  SINGER_DEV = 3246,
  SINGER_PROD = 3177,
  COVETRUS_DEV = 3201,
  COVETRUS_PROD = 3390,
  MIDDLEBY_DEV = 3236,
  MIDDLEBY_PROD = 3263,
  PJP_DEV = 3146,
  PJP_PROD = 3161,
  SYSCO_DEV = 3248
}
