import { AxiosMethods, AutoRFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import { RfqBody } from './types';

export enum AutoRFQMutationsKey {
  CREATE = 'CREATE_AUTO_RFQ',
  DELETE = 'DELETE_AUTO_RFQ'
}

export const createAutoRfqMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.CREATE],
  mutationFn: (body: RfqBody) =>
    axiosHelper({
      endpoint: AutoRFQApi.Create,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_API,
      body
    })
});

export const deleteRfqMutation = () => ({
  mutationKey: [AutoRFQMutationsKey.DELETE],
  mutationFn: (id: string | number) =>
    axiosHelper({
      endpoint: AutoRFQApi.DeleteSingle.replace(':id', id.toString()),
      baseURL: process.env.REVOPS_API,
      method: AxiosMethods.DELETE
    })
});
