import { SDropdownMenu } from '@setvi/shared/components';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItemProps } from '.';
import { Item } from './Item';

interface IMenuItemDropdown extends MenuItemProps {
  collapsedDrawer: boolean;
  menuItems: MenuItemProps[];
}

export const MenuItemDropdown = ({
  collapsedDrawer,
  title,
  menuItems,
  icon,
  baseRoute
}: IMenuItemDropdown) => {
  const navigate = useNavigate();
  const items = useMemo(
    () =>
      menuItems.map(el => ({
        icon: el.icon,
        text: el.title,
        divider: el.divider,
        onClick: () => navigate(el.route)
      })),
    [navigate, menuItems]
  );
  const location = useLocation();

  return (
    <SDropdownMenu
      menuItems={items}
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}>
      <Item
        selected={location.pathname.startsWith(baseRoute)}
        title={title}
        icon={icon}
        collapsedDrawer={collapsedDrawer}
      />
    </SDropdownMenu>
  );
};
