import { Box, makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  fadeIn: {
    opacity: 1,
    animationName: 'fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in',
    animationDuration: '1s'
  },
  '@keyframes fadeInOpacity': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}));

interface PageLoaderProps {
  showLoaderInfo?: boolean;
}

export const PageLoader = ({ showLoaderInfo = false }: PageLoaderProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <img
        className={classes.fadeIn}
        width="15%"
        src="https://euhd1msp.blob.core.windows.net/public/Setvi/Logos/SVG/Reversed/SETVI-Horizontal-icon-RGB-FullColor-Transparent.svg"
        alt="setvi-logo"
      />
      {showLoaderInfo && (
        <Box mt={5}>
          <Box fontWeight={600}>
            Just a sec! Redirecting you to the new site... 🚀
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}>
            <CircularProgress />
            <Box mt={2}>Redirecting...</Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
