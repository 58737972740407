import { useEffect, useState } from 'react';

interface SelectedProps<T> {
  preselected?: T[];
  dataKeyGetter: (data: T) => string | number;
}

export const useSelected = <T>({
  preselected,
  dataKeyGetter
}: SelectedProps<T>) => {
  const [selected, setSelected] = useState<T[]>(preselected || []);

  useEffect(() => {
    if (preselected && preselected.length > 0) setSelected(preselected);
  }, [preselected]);

  const handleSelect = (isChecked: boolean, selectedArray: T[]) => {
    if (isChecked) {
      const filteredSelectedArray = selectedArray?.filter(
        item =>
          !selected.some(
            dataItem => dataKeyGetter(dataItem) === dataKeyGetter(item)
          )
      );

      const newItems = [...selected, ...filteredSelectedArray];
      setSelected(newItems);
    } else {
      const newItems = selected?.filter(
        selItems =>
          !selectedArray.find(
            dataItem => dataKeyGetter(dataItem) === dataKeyGetter(selItems)
          )
      );
      setSelected(newItems);
    }
  };

  const isEveryItemSelected = (comparisonDataset: T[]): boolean => {
    if (selected.length === 0 || comparisonDataset.length === 0) return false;

    return comparisonDataset.every(item =>
      selected.some(i => dataKeyGetter(i) === dataKeyGetter(item))
    );
  };

  const modifySelected = (newSelected: T[]) =>
    setSelected(prev =>
      prev.map(i => {
        const exist = newSelected.find(
          j => dataKeyGetter(j) === dataKeyGetter(i)
        );
        return exist || i;
      })
    );

  return {
    selected,
    handleSelect,
    setSelected,
    isEveryItemSelected,
    modifySelected
  };
};
