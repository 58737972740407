import { Breadcrumbs, BreadcrumbsProps, withStyles } from '@material-ui/core';
import { SBreadcrumbProps } from '../..';
import { BreadcrumbItem } from '../item';

type CustomProps = Pick<
  SBreadcrumbProps<any>,
  'noWrap' | 'fontSize' | 'fontWeight'
>;

const List = withStyles({
  root: {
    lineHeight: '18px',
    '& p': { fontSize: 14, cursor: 'pointer', fontWeight: 500 },
    '& p:hover': { textDecoration: 'underline' },

    '& .MuiBreadcrumbs-separator': {
      marginLeft: 4,
      marginRight: 4
    },
    fontSize: ({ fontSize }: CustomProps) => fontSize,
    fontWeight: ({ fontWeight }: CustomProps) => fontWeight,
    '& ol': {
      flexWrap: ({ noWrap }: CustomProps) => (noWrap ? 'nowrap' : 'wrap')
    }
  }
})(({ noWrap, ...props }: BreadcrumbsProps & CustomProps) => (
  <Breadcrumbs {...props} />
));

interface BreadcrumbsListProps<T> extends SBreadcrumbProps<T> {
  expand?: boolean;
}

export const BreadcrumbsList = <T,>({
  items,
  expand,
  noWrap,
  fontSize = 14,
  fontWeight = 500,
  maxItems = 3,
  disableDefaultExpand = false,
  parseBreadcrumb,
  onBreadcrumbClick,
  ...rest
}: BreadcrumbsListProps<T>) => (
  <List
    aria-label="breadcrumb"
    fontSize={fontSize}
    fontWeight={fontWeight}
    noWrap={noWrap}
    maxItems={maxItems}
    {...rest}>
    {items?.map((item, idx) => (
      <BreadcrumbItem
        key={item.toString()}
        item={item}
        highlighted={idx + 1 === items.length}
        readOnly={!onBreadcrumbClick}
        parseBreadcrumb={parseBreadcrumb}
        onBreadcrumbClick={onBreadcrumbClick}
      />
    ))}
  </List>
);
