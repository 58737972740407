import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SAvatar, SText } from '@setvi/shared/components';
import { getInitials } from '@setvi/shared/utils';
import { useAppContext } from 'Providers/AppProvider/AppContext';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 40,
    margin: theme.spacing(2, 0)
  }
}));

const Content = () => {
  const { user } = useAppContext();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box mr={2}>
        <SAvatar
          style={{ width: 40, height: 40 }}
          src={user.HasPhoto ? user.Photo : null}
          value={
            !user.HasPhoto
              ? getInitials({
                  firstName: user.FirstName,
                  lastName: user.LastName,
                  email: user.Email
                })
              : null
          }
        />
      </Box>
      <SText weight="medium">
        {user?.FirstName} {user?.LastName}
      </SText>
    </Box>
  );
};

export default Content;
