import { useSelected } from '../../../hooks';
import { Resource } from '../../../interfaces';
import { useDialog } from '../../../providers';
import { SelectPages } from '../components/select-pages';

interface useSelectResourcesProps {
  preselected?: Resource[];
}

const dialogId = 'select-pages';
export const selectedResourceKeyGetter = 'ResourceID';

export const useSelectResources = ({
  preselected = []
}: useSelectResourcesProps = {}) => {
  const { openDialog, closeDialog } = useDialog();
  const { selected, setSelected, handleSelect } = useSelected<Resource>({
    dataKeyGetter: (resource: Resource) => resource[selectedResourceKeyGetter],
    preselected
  });

  const handleSelectPages = (resource: Resource) => {
    openDialog(
      <SelectPages
        resource={resource}
        onClose={() => closeDialog(dialogId)}
        onConfirm={sp => {
          const selectedPagesFormatted: Resource[] = sp.map(page => ({
            ...page,
            ID: page.ID,
            ResourceID: page.ItemID,
            Name: `(Slide ${page.SortOrder}) ${resource.Name}`,
            ResourceTypeID: resource.ResourceTypeID,
            Key: 'child'
          }));

          handleSelect(true, selectedPagesFormatted);
          closeDialog(dialogId);
        }}
      />,
      {
        id: dialogId
      }
    );
  };

  return {
    selectedResources: selected,
    handleSelectResources: handleSelect,
    handleSelectPages,
    setSelected
  };
};
