import { Box, withStyles, LinearProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

export const StyledProgress = withStyles(() => ({
  root: {
    borderRadius: '24px',
    height: '4px',
    backgroundColor: '#fff',

    '& .MuiLinearProgress-bar': {
      borderRadius: '24px'
    }
  }
}))(LinearProgress);

const Loading = ({
  loading,
  progress,
  initLoading
}: {
  loading: boolean;
  progress: number;
  initLoading?: boolean;
}) =>
  loading || initLoading ? (
    <Box width="100%" height="100%" bgcolor="#3855B314" borderRadius={6}>
      {initLoading ? (
        <Skeleton
          variant="rect"
          width="100%"
          height="100%"
          style={{
            borderRadius: 6
          }}
        />
      ) : (
        <Box
          p={3}
          width="100%"
          height="100%"
          display="flex"
          className="pulse"
          justifyContent="flex-end"
          flexDirection="column">
          {!initLoading && (
            <StyledProgress variant="determinate" value={progress} />
          )}
        </Box>
      )}
    </Box>
  ) : null;

export default Loading;
