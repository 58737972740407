import clsx from 'clsx';
import { Fragment, memo, useEffect } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import { Box, Theme, makeStyles } from '@material-ui/core';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

interface SInfiniteScrollProps<T> {
  grid: T[][];
  vList: VirtualItem[];
  vGrid: VirtualItem[];
  height: number;
  options?: Record<string, string | number>;
  children?: JSX.Element;
  containerClass?: string;
  wrapperClass?: string;
  intersectionOptions?: IntersectionOptions;

  renderDragItem?: (element: T) => Element;
  onWrapperClick?: (element: T) => void;
  onWrapperDrag?: (element: T) => void;
  onWrapperDrop?: (element: T) => void;
  fetchMore?: () => void;
  keyGetter: (element: T) => string | number;
  render: (element: T, index?: number) => JSX.Element;
}

export const useStyles = makeStyles<
  Theme,
  { wrapperClick: boolean; height: number }
>({
  wrapper: {
    cursor: ({ wrapperClick }) => wrapperClick && 'pointer',
    position: 'absolute',
    top: 0,
    left: 0
  },
  body: {
    height: ({ height }) => (height ? `${height}px` : '100%'),
    width: '100%',
    position: 'relative'
  }
});

const VirtualGrid = <T,>({
  grid,
  vList,
  vGrid,
  height,
  options,
  children,
  wrapperClass,
  containerClass,
  intersectionOptions,

  render,
  fetchMore,
  keyGetter,
  onWrapperDrop,
  onWrapperDrag,
  onWrapperClick,
  renderDragItem
}: SInfiniteScrollProps<T>) => {
  const classes = useStyles({ wrapperClick: !!onWrapperClick, height });
  const { ref, inView } = useInView(intersectionOptions);

  useEffect(() => {
    if (inView) fetchMore?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Box className={clsx(classes.body, containerClass)}>
      {vList?.map((virtualRow, i) => (
        <Fragment key={virtualRow.index}>
          {vGrid?.map(virtualColumn => {
            const element = grid[virtualRow?.index][virtualColumn?.index];

            if (!element) return null;

            return (
              <Box
                key={keyGetter(element)}
                onClick={() => onWrapperClick?.(element)}
                draggable={!!onWrapperDrag}
                onDragStart={() => onWrapperDrag?.(element)}
                className={clsx(classes.wrapper, wrapperClass)}
                onDragOver={e => e.preventDefault()}
                onDragStartCapture={e => {
                  const div = renderDragItem?.(element);
                  if (div) e.dataTransfer.setDragImage(div, 0, 0);
                }}
                onDrop={ev => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  onWrapperDrop?.(element);
                }}
                style={{
                  width: `${virtualColumn.size}px !important`,
                  height: `${virtualRow.size}px !important`,

                  transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start - ((options?.scrollMargin as number) || 0)}px) scale(0.95)`
                }}
                // @ts-ignore ref is working but is not valid prop on dype definition
                ref={virtualRow?.index + 1 === grid?.length ? ref : null}>
                {render(element, i)}
              </Box>
            );
          })}
        </Fragment>
      ))}

      {children}
    </Box>
  );
};

const SVirtualGrid = memo(VirtualGrid) as typeof VirtualGrid;
export default SVirtualGrid;
