export enum LINK_VALIDATION {
  MAX_LINK_NAME = 100,
  MAX_LINK_ADDRESS = 2048
}

export enum VALIDATORS {
  NAME_MAX_LENGTH = 120,
  DESCRIPTION_MAX_LENGTH = 3500,
  PRICE = 15
}
