import { axiosHelper } from '@setvi/shared/services';
import { AxiosMethods, AdminBannersApi } from '@setvi/shared/enums';

import {
  BannerBody,
  BannersOrderBody,
  BannerStatusParams,
  UpdateBrandingBody,
  UpdateDefaultBannerBody
} from './Types';

export const createBannerMutation = () => ({
  mutationFn: (body: BannerBody) =>
    axiosHelper({
      endpoint: AdminBannersApi.CreateBanner,
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const editBannerMutation = () => ({
  mutationFn: (body: BannerBody) =>
    axiosHelper({
      endpoint: AdminBannersApi.EditBanner,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const changeBannersStatusMutation = () => ({
  mutationFn: (params: BannerStatusParams) =>
    axiosHelper({
      endpoint: AdminBannersApi.ChangeBannersStatus.replace(
        ':statusId',
        params.StatusId.toString()
      ),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        bannerId: params?.BannerIds || []
      }
    })
});

export const changeBannersOrderMutation = () => ({
  mutationFn: (body: BannersOrderBody) =>
    axiosHelper({
      endpoint: AdminBannersApi.ChangeBannersOrder,
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const updateDefaultBannersMutation = () => ({
  mutationFn: (body: UpdateDefaultBannerBody) =>
    axiosHelper({
      endpoint: AdminBannersApi.UpdateDefaultBanners,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const updateBrandingMutation = () => ({
  mutationFn: (body: UpdateBrandingBody) =>
    axiosHelper({
      endpoint: AdminBannersApi.UpdateBranding,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});

export const deleteBannersMutation = () => ({
  mutationFn: (ids: number[]) =>
    axiosHelper({
      endpoint: AdminBannersApi.DeleteBanners,
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        bannerId: ids
      }
    })
});
