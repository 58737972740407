// Generated by https://quicktype.io

export interface SetSalesforceEmailMutation {
  Status: string;
  Data: CrmUsers;
  Error: null;
}

export interface CrmUsers {
  totalSize: number;
  done: boolean;
  records: User[];
  results?: SingerUser[];
}

export interface User {
  attributes: Attributes;
  Id: string;
}

export interface SingerUser {
  archived: boolean;
  createdAt: string;
  email: string;
  id: string;
  lastName: string;
  type: string;
  updatedAt: string;
  userId: number;
  userIdIncludingInactive: number;
}

export interface Attributes {
  type: string;
  url: string;
}

export interface GetNylasUserStatusMutation {
  Status: string;
  Data: NylasUserStatus;
  Error: null;
}

export interface NylasUserStatus {
  ApiVersion: string;
  Email: string;
  Name: string;
  Status: string;
}

export enum NylasStatus {
  // v3
  Valid = 'valid',
  Invalid = 'invalid',
  // v2
  Running = 'running'
}
