import { SvgIcon, SvgIconProps } from '@material-ui/core';

/* *********************************************************************
    INSTRUCTIONS FOR ADDING NEW ICONS
    MAKE SURE THAT NAME IS GIVING DESCRIPTION OF ICON
    'Icon' SHOULD BE INCLUDED IN NAME
    Use 'color' attribute if you want to use color provided by theme 
    Use 'fontSize' attribute to handle size of icon
    Use 'htmlColor' attribute to add custom color to icon
    Use 'inheritViewBox' attribute if you want to override defualt viewBox
    Use 'viewBox' attribute if you want to add custom viewBox
    refrences: https://mui.com/material-ui/api/svg-icon/#main-content
********************************************************************** */

export const AdminCampaignsIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
    <g clipPath="url(#clip0_1141_15044)">
      <path
        d="M20 7.738L12.072 14.838L4 7.716V19.5H14V21.5H3C2.73478 21.5 2.48043 21.3946 2.29289 21.2071C2.10536 21.0196 2 20.7652 2 20.5V4.5C2 4.23478 2.10536 3.98043 2.29289 3.79289C2.48043 3.60536 2.73478 3.5 3 3.5H21C21.2652 3.5 21.5196 3.60536 21.7071 3.79289C21.8946 3.98043 22 4.23478 22 4.5V13.5H20V7.738ZM19.501 5.5H4.511L12.061 12.162L19.502 5.5H19.501ZM17.05 20.048C16.9831 19.6857 16.9831 19.3143 17.05 18.952L16.036 18.366L17.036 16.634L18.05 17.22C18.328 16.982 18.649 16.795 19 16.67V15.5H21V16.67C21.351 16.795 21.672 16.982 21.95 17.22L22.964 16.634L23.964 18.366L22.95 18.952C23.0169 19.3143 23.0169 19.6857 22.95 20.048L23.964 20.634L22.964 22.366L21.95 21.78C21.6699 22.0203 21.3478 22.2067 21 22.33V23.5H19V22.33C18.6522 22.2067 18.3301 22.0203 18.05 21.78L17.036 22.366L16.036 20.634L17.05 20.048ZM20 20.5C20.2652 20.5 20.5196 20.3946 20.7071 20.2071C20.8946 20.0196 21 19.7652 21 19.5C21 19.2348 20.8946 18.9804 20.7071 18.7929C20.5196 18.6054 20.2652 18.5 20 18.5C19.7348 18.5 19.4804 18.6054 19.2929 18.7929C19.1054 18.9804 19 19.2348 19 19.5C19 19.7652 19.1054 20.0196 19.2929 20.2071C19.4804 20.3946 19.7348 20.5 20 20.5Z"
        fill={props?.htmlColor || '#696974'}
      />
    </g>
    <defs>
      <clipPath id="clip0_1141_15044">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const AdminHomeScreenIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="M19 21.4999H5C4.73478 21.4999 4.48043 21.3946 4.29289 21.207C4.10536 21.0195 4 20.7651 4 20.4999V11.4999H1L11.327 2.11192C11.5111 1.9444 11.7511 1.85156 12 1.85156C12.2489 1.85156 12.4889 1.9444 12.673 2.11192L23 11.4999H20V20.4999C20 20.7651 19.8946 21.0195 19.7071 21.207C19.5196 21.3946 19.2652 21.4999 19 21.4999ZM6 19.4999H18V9.65692L12 4.20292L6 9.65692V19.4999ZM8.591 14.3089C8.46429 13.7757 8.46429 13.2202 8.591 12.6869L7.6 12.1149L8.6 10.3829L9.591 10.9559C9.98885 10.5789 10.4698 10.3007 10.995 10.1439V8.99992H12.995V10.1439C13.527 10.3029 14.005 10.5839 14.399 10.9559L15.39 10.3829L16.39 12.1139L15.399 12.6869C15.5257 13.2202 15.5257 13.7757 15.399 14.3089L16.39 14.8809L15.39 16.6119L14.399 16.0399C14.001 16.4166 13.5201 16.6944 12.995 16.8509V17.9959H10.995V16.8499C10.4699 16.6934 9.98896 16.4156 9.591 16.0389L8.6 16.6109L7.6 14.8809L8.591 14.3079V14.3089ZM11.995 14.9969C12.1965 15.0043 12.3973 14.971 12.5856 14.8989C12.7739 14.8269 12.9458 14.7177 13.0909 14.5778C13.236 14.4378 13.3514 14.2701 13.4303 14.0846C13.5091 13.899 13.5498 13.6995 13.5498 13.4979C13.5498 13.2963 13.5091 13.0968 13.4303 12.9113C13.3514 12.7257 13.236 12.558 13.0909 12.4181C12.9458 12.2782 12.7739 12.1689 12.5856 12.0969C12.3973 12.0249 12.1965 11.9916 11.995 11.9989C11.6069 12.0131 11.2395 12.1772 10.9699 12.4568C10.7004 12.7364 10.5498 13.1096 10.5498 13.4979C10.5498 13.8863 10.7004 14.2595 10.9699 14.539C11.2395 14.8186 11.6069 14.9827 11.995 14.9969Z"
      fill={props?.htmlColor || '#696974'}
    />
  </SvgIcon>
);

export const AdminResourcesIcon = (props: SvgIconProps) => (
  <SvgIcon width="18" height="21" viewBox="0 0 18 21" fill="none" {...props}>
    <path
      d="M5.595 11.312C5.46821 10.7784 5.46821 10.2226 5.595 9.689L4.603 9.116L5.603 7.384L6.595 7.957C6.99316 7.57988 7.47446 7.30172 8 7.145V6H10V7.145C10.532 7.303 11.012 7.585 11.405 7.957L12.397 7.384L13.397 9.116L12.405 9.689C12.5316 10.2223 12.5316 10.7777 12.405 11.311L13.397 11.884L12.397 13.616L11.405 13.043C11.0068 13.4201 10.5255 13.6983 10 13.855V15H8V13.855C7.47446 13.6983 6.99316 13.4201 6.595 13.043L5.603 13.616L4.603 11.884L5.595 11.312ZM9 12C9.39782 12 9.77936 11.842 10.0607 11.5607C10.342 11.2794 10.5 10.8978 10.5 10.5C10.5 10.1022 10.342 9.72064 10.0607 9.43934C9.77936 9.15804 9.39782 9 9 9C8.60218 9 8.22064 9.15804 7.93934 9.43934C7.65804 9.72064 7.5 10.1022 7.5 10.5C7.5 10.8978 7.65804 11.2794 7.93934 11.5607C8.22064 11.842 8.60218 12 9 12ZM12 2.5H2V18.5H16V6.5H12V2.5ZM0 1.492C0 0.944 0.447 0.5 0.999 0.5H13L18 5.5V19.493C18.0009 19.6243 17.976 19.7545 17.9266 19.8762C17.8772 19.9979 17.8043 20.1087 17.7121 20.2022C17.6199 20.2957 17.5101 20.3701 17.3892 20.4212C17.2682 20.4723 17.1383 20.4991 17.007 20.5H0.993C0.730378 20.4982 0.479017 20.3931 0.293218 20.2075C0.107418 20.0219 0.00209465 19.7706 0 19.508V1.492Z"
      fill={props?.htmlColor || '#696974'}
    />
  </SvgIcon>
);

export const AdminUsersIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
    <path
      d="M12 14.5V16.5C10.4087 16.5 8.88258 17.1321 7.75736 18.2574C6.63214 19.3826 6 20.9087 6 22.5H4C4 20.3783 4.84285 18.3434 6.34315 16.8431C7.84344 15.3429 9.87827 14.5 12 14.5ZM12 13.5C8.685 13.5 6 10.815 6 7.5C6 4.185 8.685 1.5 12 1.5C15.315 1.5 18 4.185 18 7.5C18 10.815 15.315 13.5 12 13.5ZM12 11.5C14.21 11.5 16 9.71 16 7.5C16 5.29 14.21 3.5 12 3.5C9.79 3.5 8 5.29 8 7.5C8 9.71 9.79 11.5 12 11.5ZM14.595 19.312C14.4682 18.7784 14.4682 18.2226 14.595 17.689L13.603 17.116L14.603 15.384L15.595 15.957C15.9932 15.5799 16.4745 15.3017 17 15.145V14H19V15.145C19.532 15.303 20.012 15.585 20.405 15.957L21.397 15.384L22.397 17.116L21.405 17.689C21.5316 18.2222 21.5316 18.7778 21.405 19.311L22.397 19.884L21.397 21.616L20.405 21.043C20.0068 21.4201 19.5255 21.6983 19 21.855V23H17V21.855C16.4745 21.6983 15.9932 21.4201 15.595 21.043L14.603 21.616L13.603 19.884L14.595 19.312ZM18 20C18.3978 20 18.7794 19.842 19.0607 19.5607C19.342 19.2794 19.5 18.8978 19.5 18.5C19.5 18.1022 19.342 17.7206 19.0607 17.4393C18.7794 17.158 18.3978 17 18 17C17.6022 17 17.2206 17.158 16.9393 17.4393C16.658 17.7206 16.5 18.1022 16.5 18.5C16.5 18.8978 16.658 19.2794 16.9393 19.5607C17.2206 19.842 17.6022 20 18 20Z"
      fill={props?.htmlColor || '#696974'}
    />
  </SvgIcon>
);

export const BoxIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="15" viewBox="0 0 28 15" fill="none" {...props}>
    <path
      d="M14.6819 4.5625C12.7781 4.5625 11.1214 5.63342 10.287 7.20564C9.45236 5.63342 7.79652 4.5625 5.89138 4.5625C4.77272 4.5625 3.73924 4.93257 2.90532 5.55752V1.36602C2.89546 0.824414 2.45376 0.390869 1.9103 0.390869C1.36683 0.390869 0.929428 0.824414 0.916992 1.36602V9.61979H0.917993C0.961876 12.3298 3.17033 14.513 5.89138 14.513C7.79652 14.513 9.45236 13.4411 10.287 11.8704C11.1214 13.4411 12.7781 14.513 14.6819 14.513C17.4287 14.513 19.6569 12.2857 19.6569 9.53674C19.6569 6.78925 17.4287 4.5625 14.6819 4.5625ZM5.89138 12.5211C4.24312 12.5211 2.90532 11.1859 2.90532 9.53745C2.90532 7.88962 4.24312 6.55425 5.89138 6.55425C7.53951 6.55425 8.87444 7.88962 8.87444 9.53674C8.87444 11.1854 7.53951 12.5211 5.89138 12.5211ZM14.6819 12.5211C13.0332 12.5211 11.6964 11.1854 11.6964 9.53674C11.6964 7.88962 13.0332 6.55425 14.6819 6.55425C16.3299 6.55425 17.666 7.88962 17.666 9.53674C17.666 11.1854 16.3299 12.5211 14.6819 12.5211Z"
      fill="#0071F7"
    />
    <path
      d="M27.3775 12.8457L24.6733 9.5287L27.3808 6.20529C27.7232 5.76689 27.625 5.15467 27.1524 4.82947C26.6793 4.50142 26.0154 4.58547 25.6494 5.01087V5.01029L23.32 7.86513L20.9927 5.01029V5.01087C20.6304 4.58547 19.9624 4.50142 19.4907 4.82947C19.019 5.15495 18.9203 5.76689 19.2644 6.20529H19.2634L21.9662 9.5287L19.2634 12.8457H19.2644C18.9203 13.2855 19.019 13.8957 19.4907 14.2223C19.9624 14.549 20.6304 14.4658 20.9927 14.0398L23.32 11.189L25.6461 14.0398C26.0126 14.4658 26.6761 14.549 27.1492 14.2223C27.622 13.8957 27.7206 13.2855 27.3775 12.8457Z"
      fill="#0071F7"
    />
  </SvgIcon>
);

export const DropboxIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="23" viewBox="0 0 28 23" fill="none" {...props}>
    <path
      d="M7.08332 8.55737L13.7508 4.30737L7.08332 0.057373L0.416992 4.30737L7.08332 8.55737Z"
      fill="#0061FF"
    />
    <path
      d="M20.4173 8.55737L27.0836 4.30737L20.4173 0.057373L13.751 4.30737L20.4173 8.55737Z"
      fill="#0061FF"
    />
    <path
      d="M13.7508 12.8074L7.08332 8.55737L0.416992 12.8074L7.08332 17.0574L13.7508 12.8074Z"
      fill="#0061FF"
    />
    <path
      d="M20.4173 17.0574L27.0836 12.8074L20.4173 8.55737L13.751 12.8074L20.4173 17.0574Z"
      fill="#0061FF"
    />
    <path
      d="M20.4178 18.4741L13.7514 14.2241L7.08398 18.4741L13.7514 22.7241L20.4178 18.4741Z"
      fill="#0061FF"
    />
  </SvgIcon>
);

export const OnedriveIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="18" viewBox="0 0 28 18" fill="none" {...props}>
    <path
      d="M16.5273 12.2741L22.0316 6.93409C20.8882 2.4114 16.3472 -0.314708 11.889 0.845175C9.88129 1.36751 8.13753 2.63007 6.99805 4.38641C7.08322 4.38423 16.5273 12.2741 16.5273 12.2741Z"
      fill="#0364B8"
    />
    <path
      d="M10.5855 5.3913L10.5852 5.39222C9.53348 4.73139 8.32051 4.38209 7.08337 4.3838C7.05478 4.3838 7.02669 4.38563 6.99819 4.38597C3.31817 4.43222 0.371949 7.49605 0.417514 11.2292C0.434033 12.5833 0.85112 13.9012 1.61472 15.0121L9.86103 13.9644L16.1833 8.79276L10.5855 5.3913Z"
      fill="#0078D4"
    />
    <path
      d="M22.032 6.93379C21.911 6.9256 21.7894 6.91992 21.6663 6.91992C20.9279 6.91919 20.1972 7.0722 19.5194 7.36947L19.5192 7.36889L16.1836 8.79288L19.8609 13.4009L26.4349 15.0221C27.8536 12.3503 26.8686 9.01766 24.2349 7.57847C23.5543 7.2066 22.8032 6.98679 22.032 6.93382L22.032 6.93379Z"
      fill="#1490DF"
    />
    <path
      d="M1.61523 15.0143C2.86038 16.8303 4.90327 17.9127 7.08388 17.9115H21.6666C23.6586 17.912 25.4901 16.8031 26.4352 15.0242L16.1838 8.79492L1.61523 15.0143Z"
      fill="#28A8EA"
    />
  </SvgIcon>
);

export const GoogleDriveIcon = (props: SvgIconProps) => (
  <SvgIcon width="28" height="24" viewBox="0 0 28 24" fill="none" {...props}>
    <path
      d="M2.92549 20.4622L4.09788 22.4957C4.34148 22.9238 4.69215 23.2604 5.10408 23.5055L9.30949 20.5238L9.31715 16.2431L5.12043 14.3523L0.916996 16.228C0.916163 16.7015 1.03747 17.1752 1.28111 17.6032L2.92549 20.4622Z"
      fill="#0066DD"
    />
    <path
      d="M14.2656 7.69883L14.485 2.12258L10.0785 0.421387C9.66569 0.665033 9.31382 1.0004 9.06866 1.42758L1.286 14.8539C1.0409 15.2812 0.917826 15.7544 0.916992 16.2278L9.31715 16.2428L14.2656 7.69883Z"
      fill="#00AD3C"
    />
    <path
      d="M14.2653 7.69894L18.3019 5.52738L18.4783 0.436492C18.0664 0.191388 17.5931 0.0683669 17.1044 0.0674815L11.4534 0.0573773C10.9646 0.0564919 10.4909 0.193107 10.0781 0.421492L14.2653 7.69894Z"
      fill="#00831E"
    />
    <path
      d="M19.1839 16.2605L9.31751 16.2429L5.10449 23.5053C5.51642 23.7504 5.9897 23.8734 6.4784 23.8743L21.9957 23.902C22.4845 23.9029 22.9582 23.7663 23.371 23.5379L23.3874 18.6598L19.1839 16.2605Z"
      fill="#0084FF"
    />
    <path
      d="M23.371 23.5381C23.7838 23.2944 24.1357 22.9591 24.3808 22.5319L24.871 21.6928L27.2151 17.6496C27.4602 17.2224 27.5832 16.7492 27.5841 16.2757L22.7288 13.7209L19.1992 16.2607L23.371 23.5381Z"
      fill="#FF4131"
    />
    <path
      d="M23.3523 8.17345L19.4849 1.44637C19.2414 1.01824 18.8907 0.681628 18.4787 0.436523L14.2656 7.69897L19.1835 16.2607L27.5684 16.2757C27.5692 15.8023 27.4479 15.3286 27.2043 14.9004L23.3523 8.17345Z"
      fill="#FFBA00"
    />
  </SvgIcon>
);

export const GoogleIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.14 12.7047C21.14 12.0665 21.0827 11.4529 20.9764 10.8638H12.5V14.3451H17.3436C17.135 15.4701 16.5009 16.4233 15.5477 17.0615V19.3197H18.4564C20.1582 17.7529 21.14 15.4456 21.14 12.7047Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4998 21.5C14.9298 21.5 16.967 20.6941 18.4561 19.3195L15.5475 17.0613C14.7416 17.6013 13.7107 17.9204 12.4998 17.9204C10.1557 17.9204 8.17158 16.3372 7.46385 14.21H4.45703V16.5418C5.93794 19.4831 8.98158 21.5 12.4998 21.5Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46409 14.2098C7.28409 13.6698 7.18182 13.093 7.18182 12.4998C7.18182 11.9066 7.28409 11.3298 7.46409 10.7898V8.45801H4.45727C3.84773 9.67301 3.5 11.0476 3.5 12.4998C3.5 13.9521 3.84773 15.3266 4.45727 16.5416L7.46409 14.2098Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4998 7.07955C13.8211 7.07955 15.0075 7.53364 15.9402 8.42545L18.5216 5.84409C16.9629 4.39182 14.9257 3.5 12.4998 3.5C8.98158 3.5 5.93794 5.51682 4.45703 8.45818L7.46385 10.79C8.17158 8.66273 10.1557 7.07955 12.4998 7.07955Z"
      fill="#EA4335"
    />
  </SvgIcon>
);
