import {
  User,
  CompanyData,
  CompanyModule
} from '@setvi/shared/services/react-query/query/user/types';
import { createContext, useContext } from 'react';

export type AppContextType = {
  token: string | null;
  setToken: (token: string) => void;
  algoliaKey: string | null;
  setAlgoliaKey: (key: string) => void;
  nylasToken: string | null;
  setNylasToken: (key: string) => void;
  user: User | null;
  setUser: (user: User) => void;
  sasToken: string | null;
  setSasToken: (sasToken: string) => void;
  companyData: CompanyData | null;
  setCompanyData: (data: CompanyData) => void;
  companyModules: CompanyModule[];
  setCompanyModules: (modules: CompanyModule[]) => void;
  setLayout: (layout: boolean) => void;
  layout: boolean;
};

export const AppContext = createContext<AppContextType>({
  token: null,
  setToken: () => {},
  algoliaKey: null,
  setAlgoliaKey: () => {},
  nylasToken: null,
  setNylasToken: () => {},
  user: null,
  setUser: () => {},
  sasToken: null,
  setSasToken: () => {},
  companyData: null,
  setCompanyData: () => {},
  companyModules: null,
  setCompanyModules: () => {},
  setLayout: () => {},
  layout: true
});

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(`useAppContext must be used within a AppContext.Provider`);
  }

  return context;
};
