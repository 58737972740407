import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';

import { axiosHelper } from '@setvi/shared/services';
import { BaseQueryResponse } from '@setvi/shared/interfaces';
import { AdminBannersApi, AxiosMethods } from '@setvi/shared/enums';

import {
  AdminBannerDetails,
  AdminBannerGroup,
  AdminBannersQueryResponse,
  AdminBannersQueryVariables,
  AdminBranding,
  AdminDefaultBanners,
  HomeScreenCounts
} from './Types';

export enum AdminBannersQueryKey {
  COUNTS = 'COUNTS',
  BANNER = 'BANNER',
  ALL_BANNERS = 'ALL_BANNERS',
  BANNER_GROUPS = 'BANNER_GROUPS',
  DEFAULT_BANNERS = 'DEFAULT_BANNERS',
  BRANDING = 'BRANDING'
}

export const getAdminBannersQuery = (
  params: AdminBannersQueryVariables
): UseInfiniteQueryOptions<BaseQueryResponse<AdminBannersQueryResponse>> => ({
  queryKey: [AdminBannersQueryKey.ALL_BANNERS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: AdminBannersApi.GetBanners,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      }
    }),
  getNextPageParam: (
    lastPage: BaseQueryResponse<AdminBannersQueryResponse>,
    allPages: BaseQueryResponse<AdminBannersQueryResponse>[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getAdminBannerQuery = (id: string) => ({
  queryKey: [AdminBannersQueryKey.BANNER, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminBannersApi.GetBanner.replace(':id', id),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (data: BaseQueryResponse<AdminBannerDetails>) => data?.Data,
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getAdminBannerGroupsQuery = (ids: number[]) => ({
  queryKey: [AdminBannersQueryKey.BANNER_GROUPS, ids],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminBannersApi.GetBannerGroups,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        bannerId: ids
      }
    }),
  select: (data: BaseQueryResponse<AdminBannerGroup>) => data?.Data,
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getAdminDefaultBannersQuery = () => ({
  queryKey: [AdminBannersQueryKey.DEFAULT_BANNERS],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminBannersApi.GetDefaultBanners,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: BaseQueryResponse<AdminDefaultBanners>) => res?.Data,
  refetchOnWindowFocus: false
});

export const getAdminBrandingQuery = () => ({
  queryKey: [AdminBannersQueryKey.BRANDING],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminBannersApi.GetBranding,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: BaseQueryResponse<AdminBranding>) => res?.Data,
  refetchOnWindowFocus: false
});

export const getHomeScreenCountsQuery = () => ({
  queryKey: [AdminBannersQueryKey.COUNTS],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminBannersApi.GetCounts,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: BaseQueryResponse<HomeScreenCounts>) => res?.Data,
  refetchOnWindowFocus: false
});
