import { useEffect, useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { TableRow } from '@material-ui/core';
import { useSortable } from '@dnd-kit/sortable';
import { useInView, IntersectionOptions } from 'react-intersection-observer';

export interface DraggableAttributes {
  role: string;
  tabIndex: number;
  'aria-disabled': boolean;
  'aria-pressed': boolean | undefined;
  'aria-roledescription': string;
  'aria-describedby': string;
}

interface SortableItemProps<T> {
  id: number | string;
  index: number;
  array: T[];
  element: T;
  options?: IntersectionOptions;
  disabled?: boolean;

  rowStyleGetter?: (element: T, index: number) => React.CSSProperties;
  fetchMore?: () => void;
  render: (
    element: T,
    index: number,
    attributes: DraggableAttributes,
    //  @typescript-eslint/ban-types
    listeners: Record<string, any> | undefined
  ) => JSX.Element;
}

const SortableItem = <T,>({
  id,
  index,
  array,
  element,
  options,
  disabled = false,
  fetchMore,
  rowStyleGetter,
  render
}: SortableItemProps<T>) => {
  const { ref, inView } = useInView(options);

  const { active, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const dndStyle = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
      borderBottom: '1px solid #E6E7E9',
      zIndex: active ? 5 : 1
    }),
    [active, transition, transform]
  );

  useEffect(() => {
    if (inView) fetchMore?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <TableRow
      hover={!active}
      ref={el => {
        setNodeRef(el);
        if (index + 1 === array?.length) ref(el);
      }}
      style={{ ...rowStyleGetter?.(element, index), ...dndStyle }}>
      {render(element, Number(id), attributes, listeners)}
    </TableRow>
  );
};

export default SortableItem;
