import { useCallback, useState, MouseEvent, ChangeEvent } from 'react';
import { Resource } from '@setvi/shared/interfaces';
import { useSnackbar } from 'notistack';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { useStyles } from './style';
import { Overlay } from '../../overlay';
import SETVIImage from '../../../../setvi-image';
import SText from '../../../../sui/stext';
import { GetResourceTypeIcon } from '../../../../../utils';
import { SCheckbox, SDropdownMenu, SRadio } from '../../../..';
import { useSelectPagesDropdown } from '../../../hooks/useSelectPagesDropdown';
import { resourceView } from '../../../../../utils/viewer';
import { AxiosMethods, ResourceApi, ResourceType } from '../../../../../enums';
import { selectedResourceKeyGetter } from '../../../hooks/useSelectResources';
import { axiosHelper } from '../../../../../services';

interface ItemProps {
  multiple?: boolean;
  resource: Resource;
  isLoading?: boolean;
  onSelect: (checked: boolean, resources: any) => void;
  onSelectPages: (resource: Resource) => void;
  isShareable?: boolean;
  selected: Resource[];
}

// TODO: Refactor into a pure component
export const Item = ({
  multiple = true,
  resource,
  selected,
  isLoading,
  onSelect,
  onSelectPages,
  isShareable
}: ItemProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isChecked = selected?.some(sr =>
    sr.ResourceID.includes(resource.ResourceID)
  );
  const { enqueueSnackbar } = useSnackbar();

  const hasSubmenu = [
    ResourceType.Presentation,
    ResourceType.PdfDocument
  ].includes(resource?.ResourceTypeID);

  const hasChildResources = [
    ResourceType.Presentation,
    ResourceType.PdfDocument,
    ResourceType.KeyNote,
    ResourceType.Powerpoint,
    ResourceType.PhotoAlbum
  ].includes(resource?.ResourceTypeID);

  const useSubmenu = resource?.Pages > 1 && !isChecked && isShareable;

  const onThumbnailClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      resourceView({
        name: resource.Name,
        resourceTypeId: resource.ResourceTypeID,
        resourceId: resource.ResourceID
      });
    },
    [resource]
  );

  const fetchResourceDetails = async (
    id: Resource['ID']
  ): Promise<Resource | null> => {
    setLoading(true);
    try {
      const res = await axiosHelper({
        endpoint: ResourceApi.GetResource.replace(':resourceId', id.toString()),
        method: AxiosMethods.GET
      });
      return res;
    } catch (error) {
      enqueueSnackbar('Error occured while fetching resource data.', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
    return null;
  };

  const handleSelectAllPages = async (res: Resource) => {
    // removing entire file and all selected pages/slides from this file
    if (isChecked)
      return onSelect(
        false,
        selected.filter(i =>
          i.ResourceID.includes(res[selectedResourceKeyGetter])
        )
      );

    if (
      hasChildResources &&
      (!res.ChildResources || res.ChildResources.length === 0)
    ) {
      const r = await fetchResourceDetails(resource.ID);
      return r && onSelect(true, [r]);
    }

    onSelect(true, [res]);
  };

  const handleSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.checked &&
      hasChildResources &&
      (!resource.ChildResources || resource.ChildResources.length === 0)
    ) {
      const res = await fetchResourceDetails(resource.ID);
      return res && handleSelectAllPages(res);
    }

    if (hasSubmenu) return handleSelectAllPages(resource);

    onSelect(e.target.checked, [resource]);
  };

  const { menuItems } = useSelectPagesDropdown({
    resource,
    isChecked,
    onSelectPages,
    onSelectAllPages: handleSelectAllPages
  });

  const checkbox =
    hasSubmenu && useSubmenu && onSelectPages ? (
      <SDropdownMenu menuItems={menuItems}>
        <SCheckbox
          checked={isChecked}
          disabled={!isShareable}
          tooltip={!isShareable && 'This resource is not shareable'}
        />
      </SDropdownMenu>
    ) : (
      <SCheckbox
        checked={isChecked}
        onChange={handleSelect}
        disabled={!isShareable}
        tooltip={!isShareable && 'This resource is not shareable'}
      />
    );

  const radio =
    hasSubmenu && useSubmenu && onSelectPages ? (
      <SDropdownMenu menuItems={menuItems}>
        <SRadio
          name="resource"
          value={resource.ResourceID}
          checked={isChecked}
          onChange={handleSelect}
          disabled={!isShareable}
          tooltip={!isShareable && 'This resource is not shareable'}
        />
      </SDropdownMenu>
    ) : (
      <SRadio
        name="resource"
        value={resource.ResourceID}
        checked={isChecked}
        onChange={handleSelect}
        disabled={!isShareable}
        tooltip={!isShareable && 'This resource is not shareable'}
      />
    );

  return (
    <Box className={classes.innerWrap} mb={3}>
      <Box className={classes.imageContainer} onClick={onThumbnailClick}>
        <SETVIImage
          className={classes.panelImage}
          src={resource.ThumbURLWithSas || resource.ThumbURL}
          alt="Resource"
          loading="lazy"
        />
        <Overlay />
      </Box>

      <Box flex={1} ml={2}>
        <SText title={resource.Name} weight="medium" size="sm" maxLines={2} />
        <Box display="flex" alignItems="center">
          <img
            src={GetResourceTypeIcon(resource.ResourceTypeID)}
            className={classes.resourceIcon}
          />
          <Typography variant="subtitle2" color="textSecondary">
            {resource.ResourceTypeName}
          </Typography>
        </Box>
      </Box>
      {isLoading || loading ? (
        <Box style={{ padding: 12 }}>
          <CircularProgress color="primary" size={20} thickness={4.5} />
        </Box>
      ) : (
        <Box>{multiple ? checkbox : radio}</Box>
      )}
    </Box>
  );
};
