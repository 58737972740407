import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  removeEmailServerTokenMutation,
  setEmailServerTokenMutation
} from 'Services';
import { useAppContext } from 'Providers/AppProvider/AppContext';
import { useNylas } from 'Hooks/useNylas';
import { ROUTES } from 'enumsV2';

export interface NylasUser {
  account_id: string;
  email_address: string;
  id: string;
  linked_at: number;
  name: string;
  object: string;
  organization_unit: string;
  provider: string;
  sync_state: string;
}

export const useEmailServer = () => {
  const [nylasUser, setNylasUser] = useState<NylasUser | null>(null);
  const [connected, setConnected] = useState(false);
  const { user, companyData, nylasToken, setNylasToken, setUser } =
    useAppContext();
  const [loading, setLoading] = useState(!!nylasToken);
  const { connect, getStatus } = useNylas(nylasToken);

  const { mutateAsync: updateEmailServerToken, isLoading: updateLoading } =
    useMutation(setEmailServerTokenMutation());
  const { mutateAsync: deleteEmailServerToken, isLoading: deleteLoading } =
    useMutation(removeEmailServerTokenMutation());
  const location = useLocation();
  const navigate = useNavigate();

  const connectWithParams = () => {
    const {
      EmailSyncCredentials: { ProviderID, EmailSyncAppID }
    } = companyData;
    const scope = `scopes=contacts.read_only${
      ProviderID === 1 ? 'email.send' : 'scope=email'
    }`;

    return connect({ client_id: EmailSyncAppID, scope, email: user.Email });
  };

  const disconnect = async () => {
    const Data = await deleteEmailServerToken({});

    if (!Data) throw new Error('Problem while disconnecting email server');
    setNylasUser(null);
    setNylasToken(null);
    localStorage.removeItem('nylasToken');
    setUser({
      ...user,
      EmailSyncAccessToken: '',
      EmailSyncAccountID: ''
    });
  };

  // Handle redirect from Nylas after connecting
  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const accessToken = urlParams.get('access_token');
      const accountId = urlParams.get('account_id');
      const emailAddresss = urlParams.get('email_address');

      if (accessToken && accountId) {
        updateEmailServerToken({
          body: {
            access_token: accessToken,
            accountID: accountId,
            altEmail: emailAddresss
          }
        });

        setNylasToken(accessToken);
        navigate(ROUTES.SETTINGS_INTEGRATIONS, { replace: true });
      }
    }
  }, [location.search, navigate, setNylasToken, updateEmailServerToken]);

  useEffect(() => {
    if (nylasToken) {
      setLoading(true);
      getStatus().then(currentUser => {
        setConnected(
          currentUser.sync_state && currentUser.sync_state !== 'invalid'
        );
        setNylasUser(currentUser);
        setLoading(false);
      });
    } else {
      setConnected(false);
    }
  }, [getStatus, nylasToken]);

  return {
    loading: deleteLoading || updateLoading || loading,
    connect: connectWithParams,
    disconnect,
    connected,
    nylasUser
  };
};
