import {
  Radio as MUIRadio,
  Tooltip,
  makeStyles,
  Theme,
  RadioProps
} from '@material-ui/core';
import clsx from 'clsx';
import { TextColor } from '../../enums';

const useStyles = makeStyles<Theme, { strokeColor: string }>({
  checkbox: {
    padding: '0 !important',

    '& .Mui-disabled': {
      opacity: 0.5,
      border: '1px solid red'
    }
  }
});

export interface SRadioProps extends RadioProps {
  tooltip?: string;
  disableTooltip?: boolean;
  strokeColor?: string;
}

export const SRadio = ({
  tooltip,
  disableTooltip,
  color = 'default',
  strokeColor = TextColor.LightGrey,
  ...rest
}: SRadioProps) => {
  const classes = useStyles({ strokeColor });

  return (
    <Tooltip title={tooltip || ''} disableHoverListener={disableTooltip} arrow>
      <span>
        <MUIRadio
          color="primary"
          className={clsx(classes.checkbox, rest.className)}
          {...rest}
        />
      </span>
    </Tooltip>
  );
};
