import { AxiosMethods, CommonApi } from '../../../../enums';
import { axiosHelper } from '../../../axios-instance';

export enum SlackMutationKeys {
  SEND_CHANNEL_MSG = 'send-channel-message'
}

export const sendMessageToSlackChannel = () => ({
  mutationKey: [SlackMutationKeys.SEND_CHANNEL_MSG],
  mutationFn: (body: any) =>
    axiosHelper({
      endpoint: CommonApi.SlackChannelMessages,
      method: AxiosMethods.POST,
      baseURL: process.env.REVOPS_API,
      body
    })
});
