import { AxiosMethods, AdminResourceApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

import {
  AddFeaturedResroucesInput,
  AdminResourceUploadFinishedInput,
  BatchChangeAdminResourceStatusInput,
  ChangeAdminResourceStatusInput,
  ChangeAdminResourceThumbnailInput,
  ChangeFeaturedResourcesOrderInput,
  ChangeResourceCategoryInput,
  EditFeaturedResroucesInput,
  UpdateAdminResourceCategoriesInput,
  UpdateAdminResourceIsShareableInput
} from './Types';

export enum AdminResourcesMutationsKey {
  CHANGE_RESOURCE_STATUS = 'CHANGE_RESOURCE_STATUS',
  CHANGE_RESOURCES_CATEGORY = 'CHANGE_RESOURCES_CATEGORY',
  DELETE_ADMIN_RESOURCE = 'DELETE_ADMIN_RESOURCE'
}

export enum AdminResourceStatus {
  Deleted = -1,
  Inactive = 0,
  Active = 1,
  Archived = 2
}

const STATUS_ID = ':statusId';
const RESOURCE_ID = ':resourceId';
const sourceCategoryID = ':sourceCategoryID';

export const createAdminResourceMutation = () => ({
  mutationFn: (body: any) =>
    axiosHelper({
      endpoint: AdminResourceApi.CreateResource,
      method: AxiosMethods.POST,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminResourceMutation = () => ({
  mutationFn: (body: any) =>
    axiosHelper({
      endpoint: AdminResourceApi.UpdateResource,
      method: AxiosMethods.PUT,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const changeAdminResourceStatusMutation = () => ({
  mutationFn: (params: ChangeAdminResourceStatusInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ChangeResourceStatus.replace(
        STATUS_ID,
        params.statusId.toString()
      ).replace(RESOURCE_ID, params.resourceId.toString()),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const batchChangeAdminResourcesStatusMutation = () => ({
  mutationFn: ({ resourceId, statusId }: BatchChangeAdminResourceStatusInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.BatchChangeResourceStatus.replace(
        STATUS_ID,
        statusId.toString()
      ),
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: { resourceId }
    })
});

export const changeAdminResourcesCategoryMutation = () => ({
  mutationFn: (params: ChangeResourceCategoryInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ChangeResourcesCategory.replace(
        sourceCategoryID,
        params?.sourceCategoryID?.toString()
      ),
      method: AxiosMethods.PATCH,
      params,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const changeAdminResourcesThumbnailMutation = () => ({
  mutationFn: (params: ChangeAdminResourceThumbnailInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ChangeResourceThumbnail.replace(
        ':id',
        params.resourceId.toString()
      ),
      method: AxiosMethods.PATCH,
      params,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const updateAdminResourceCategoriesMutation = () => ({
  mutationFn: (body: UpdateAdminResourceCategoriesInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.UpdateResourceCategories,
      method: AxiosMethods.PUT,
      body,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const deleteAdminResourceMutation = () => ({
  mutationFn: (id: number) =>
    axiosHelper({
      endpoint: AdminResourceApi.DeleteResource.replace(':id', id.toString()),
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL
    })
});

export const batchDeleteAdminResourcesMutation = () => ({
  mutationFn: (resourceId: number[]) =>
    axiosHelper({
      endpoint: AdminResourceApi.BatchDeleteResources,
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: { resourceId }
    })
});

//! This is temporary until backend is ready for new upload fucntionality
export const adminResourceUploadFinishedMutation = () => ({
  mutationFn: (body: AdminResourceUploadFinishedInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ResourceUploadFinished,
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    }),
  retry: false
});

export const adminResourceIsShareableMutation = () => ({
  mutationFn: (body: UpdateAdminResourceIsShareableInput[]) =>
    axiosHelper({
      endpoint: AdminResourceApi.UpdateResourcesIsShareable,
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body: {
        Resources: body
      }
    }),
  retry: false
});

export const removeFeaturedResourceMutation = () => ({
  mutationFn: (id: number) =>
    axiosHelper({
      endpoint: AdminResourceApi.RemoveFeaturedResource.replace(
        ':id',
        id.toString()
      ),
      method: AxiosMethods.DELETE,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  retry: false
});

export const addFeaturedResourceMutation = () => ({
  mutationFn: (body: AddFeaturedResroucesInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.AddFeaturedResources,
      method: AxiosMethods.POST,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    }),
  retry: false
});

export const editFeaturedResourceMutation = () => ({
  mutationFn: (body: EditFeaturedResroucesInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.EditFeaturedResource,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    }),
  retry: false
});

export const changeFeaturedResourcesOrderMutation = () => ({
  mutationFn: (body: ChangeFeaturedResourcesOrderInput) =>
    axiosHelper({
      endpoint: AdminResourceApi.ChangeFeaturedResourcesOrder,
      method: AxiosMethods.PATCH,
      baseURL: process.env.ADMIN_API_BASE_URL,
      body
    })
});
