import SText from '../../../sui/stext';
import { SBreadcrumbProps } from '../..';

interface BreadcrumbItemProps<T>
  extends Pick<
    SBreadcrumbProps<any>,
    'parseBreadcrumb' | 'onBreadcrumbClick' | 'fontSize' | 'fontWeight'
  > {
  item: T;
  highlighted?: boolean;
  readOnly?: boolean;
}

export const BreadcrumbItem = <T,>({
  item,
  highlighted,
  readOnly,
  fontWeight,
  fontSize,
  parseBreadcrumb,
  onBreadcrumbClick
}: BreadcrumbItemProps<T>) => (
  <SText
    color={highlighted ? 'primary' : 'inherit'}
    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onBreadcrumbClick?.(item);
    }}
    style={{
      fontSize,
      fontWeight,
      ...(readOnly
        ? {
            cursor: 'auto',
            textDecoration: highlighted ? 'underline' : 'none'
          }
        : {})
    }}>
    {parseBreadcrumb(item)}
  </SText>
);
