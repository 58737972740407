import React, { memo, useCallback, useState } from 'react';
import {
  Box,
  makeStyles,
  Menu,
  PopoverOrigin,
  withStyles
} from '@material-ui/core';

import SMenuItem, { ISMenuItem } from './MenuItem';
import SMoreButton from '../smore-button';
import eventEmitter from '../../libs/EventEmitter';

const StyledMenu = withStyles(({ spacing }) => ({
  paper: {
    marginTop: spacing(2),
    padding: `0px ${spacing(0)}px`,
    borderRadius: 8,
    left: spacing(0),
    '&~div': {
      opacity: 0.2
    },

    '& ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}))(Menu);

const useIconButtonStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const defaultSubmenuAnchorOrigin: PopoverOrigin = {
  horizontal: 'left',
  vertical: 'top'
};

export interface ISDropdownMenu {
  menuItems: ISMenuItem[];
  children?: JSX.Element;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  submenuTransformOrigin?: PopoverOrigin;
  submenuAnchorOrigin?: PopoverOrigin;
  onClose?: () => void;
  customMenuClass?: any;
  hasSubmenu?: boolean;
  direction?: 'horizontal' | 'vertical';
}

const SDropdownMenu = ({
  menuItems,
  onClose,
  children,
  hasSubmenu,
  anchorOrigin,
  transformOrigin,
  customMenuClass = {},
  submenuAnchorOrigin = defaultSubmenuAnchorOrigin,
  submenuTransformOrigin,
  direction = 'vertical'
}: ISDropdownMenu) => {
  const styles = useIconButtonStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      setAnchorEl(event.currentTarget);
      eventEmitter.emit('sdropdown-menu-opened');
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onClose?.();
  }, [onClose]);

  return (
    <Box className={styles.buttonContainer} onClick={e => e.stopPropagation()}>
      <Box
        width="100%"
        onClick={handleOpenMenu}
        onMouseEnter={hasSubmenu && handleOpenMenu}>
        {children || (
          <SMoreButton
            active={Boolean(anchorEl)}
            width={14}
            direction={direction}
          />
        )}
      </Box>
      {Boolean(anchorEl) && (
        <StyledMenu
          anchorEl={anchorEl}
          keepMounted
          open
          onClose={hasSubmenu ? () => setAnchorEl(null) : handleClose}
          elevation={3}
          getContentAnchorEl={null}
          anchorOrigin={
            anchorOrigin || {
              vertical: 'bottom',
              horizontal: 'right'
            }
          }
          transformOrigin={
            transformOrigin || {
              vertical: 'top',
              horizontal: 'right'
            }
          }
          className={customMenuClass}>
          <div onMouseLeave={hasSubmenu && (() => setAnchorEl(null))}>
            {menuItems.map(item => (
              <SMenuItem
                key={item.text}
                icon={item.icon}
                text={item.text}
                divider={item.divider}
                subText={item.subText}
                onClick={item.onClick}
                closeOnClick={item.closeOnClick}
                boldText={item.boldText}
                borderedBox={item.borderedBox}
                color={item.color}
                disabled={item.disabled}
                tooltipText={item.tooltipText}
                handleClose={handleClose}
                submenu={item.submenu}
                tooltipPlacement={item.tooltipPlacement}
                submenuAnchorOrigin={submenuAnchorOrigin}
                submenuTransformOrigin={submenuTransformOrigin}
              />
            ))}
          </div>
        </StyledMenu>
      )}
    </Box>
  );
};

export default memo(SDropdownMenu);
