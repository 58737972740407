import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';

import { AdminResourceApi, AxiosMethods } from '@setvi/shared/enums';
import { BaseQueryResponse } from '@setvi/shared/interfaces';
import { axiosHelper } from '@setvi/shared/services';
import { Group } from 'Services/Query/Groups/Types';

import {
  AdminCategoryResourcesQuery,
  AdminFeaturedResource,
  AdminFeaturedResourcesQueryResponse,
  AdminResourceResponse,
  AdminResourcesQuery,
  AdminUploadedResourceResponse,
  BaseQueryParams,
  CategoryResourcesQueryParams,
  GetCategoriesGroupsParams,
  GetCategoriesGroupsResponse,
  ResrouceTagsResponse
} from './Types';

export enum AdminResroucesQueryKey {
  ADMIN_RESOURCE = 'ADMIN_RESOURCE',
  ADMIN_RESOURCES = 'ADMIN_RESOURCES',
  ADMIN_RESOURCE_TAGS = 'ADMIN_RESOURCE_TAGS',
  ADMIN_CATEGORY_RESOURCES = 'ADMIN_CATEGORY_RESOURCES',
  GET_CATEGORY_GROUPS = 'GET_CATEGORY_GROUPS',
  ADMIN_RESOURCES_PROCESS_STATUS = 'ADMIN_RESOURCES_PROCESS_STATUS',
  ADMIN_FEATURED_RESOURCES = 'ADMIN_FEATURED_RESOURCES',
  ADMIN_FEATURED_RESOURCES_GROUPS = 'ADMIN_FEATURED_RESOURCES_GROUPS',
  ADMIN_FEATURED_RESOURCE = 'ADMIN_FEATURED_RESOURCE'
}

export const getAdminResourceQuery = (id: string) => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCE, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetResource.replace(':id', id),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: AdminResourceResponse) => {
    const response = {
      ...res?.Data,
      CategoryBreadcrumb: res?.Data?.ResourceCategoryBreadcrumbs?.map(
        (breadcrumb: any) => breadcrumb?.ResourceCategoryName
      ).join(' / '),
      Categories:
        res?.Data?.ResourceCategoryMembership?.map((elem: any) => ({
          Id: elem?.ResourceCategoryID,
          Name: elem?.ResourceCategoryName
        })) || []
    };

    return response;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getAdminCategoyResourcesQuery = (
  params: CategoryResourcesQueryParams
): UseInfiniteQueryOptions<AdminCategoryResourcesQuery> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_CATEGORY_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminResourceApi.GetCategoryResources.replace(
        ':id',
        params.categoryId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (
    lastPage: AdminCategoryResourcesQuery,
    allPages: AdminCategoryResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getCategoyResourcesQuery = (
  params: CategoryResourcesQueryParams
): UseInfiniteQueryOptions<AdminResourcesQuery> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: `${AdminResourceApi.GetCategoryAdminResources.replace(
        ':id',
        params.categoryId?.toString()
      )}`,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (lastPage: AdminResourcesQuery) => {
    const total = lastPage?.Data?.TotalResourcesCount;
    const currPage = lastPage?.Data?.PageNumber;
    const currSize = lastPage?.Data?.PageSize;

    return currPage * currSize >= total ? undefined : currPage + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getCategoriesGroupsQuery = (
  params: GetCategoriesGroupsParams
) => ({
  queryKey: [AdminResroucesQueryKey.GET_CATEGORY_GROUPS, params],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetCategoryGroups,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params
    }),
  select: (res: GetCategoriesGroupsResponse) => res?.Data,
  refetchOnWindowFocus: false
});

export const getResourceTagsQuery = (
  params: BaseQueryParams
): UseInfiniteQueryOptions<ResrouceTagsResponse> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCE_TAGS, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: AdminResourceApi.ResourceTags,
      method: AxiosMethods.GET,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      },
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  getNextPageParam: (
    lastPage: ResrouceTagsResponse,
    allPages: ResrouceTagsResponse[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false,
  cacheTime: 0
});

export const getAdminResourcesProcessStatusesQuery = (
  resourceId: number[]
) => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_RESOURCES_PROCESS_STATUS, resourceId],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetResourceProcessStatus,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        resourceId
      }
    }),
  select: (res: AdminUploadedResourceResponse) => res?.Data,
  refetchOnWindowFocus: false
});

export const getAdminFeaturedResourcesQuery = (
  params: BaseQueryParams
): UseInfiniteQueryOptions<
  BaseQueryResponse<AdminFeaturedResourcesQueryResponse>
> => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_FEATURED_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: AdminResourceApi.GetFeaturedResources,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        ...params,
        pageNumber: data.pageParam || params.pageNumber
      }
    }),
  getNextPageParam: (
    lastPage: BaseQueryResponse<AdminFeaturedResourcesQueryResponse>,
    allPages: BaseQueryResponse<AdminFeaturedResourcesQueryResponse>[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Items?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Items?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getFeaturedResourcesGroupsQuery = (
  featuredResourceId: number[]
) => ({
  queryKey: [
    AdminResroucesQueryKey.ADMIN_FEATURED_RESOURCES_GROUPS,
    featuredResourceId
  ],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetFeaturedResourcesGroups,
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params: {
        featuredResourceId
      }
    }),
  select: (res: BaseQueryResponse<Group>) => res?.Data,
  refetchOnWindowFocus: false
});

export const getFeaturedResourceQuery = (id: string) => ({
  queryKey: [AdminResroucesQueryKey.ADMIN_FEATURED_RESOURCE, id],
  queryFn: () =>
    axiosHelper({
      endpoint: AdminResourceApi.GetFeaturedResource.replace(':id', id),
      method: AxiosMethods.GET,
      baseURL: process.env.ADMIN_API_BASE_URL
    }),
  select: (res: BaseQueryResponse<AdminFeaturedResource>) => res?.Data,
  refetchOnWindowFocus: false
});
