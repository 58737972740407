import { useCallback, useState } from 'react';
import { ROUTES } from 'enumsV2';

const NYLAS_URL = 'https://api.nylas.com';

export const useNylas = (token: string) => {
  const [statusLoading, setStatusLoading] = useState(false);

  const getStatus = useCallback(async (): Promise<any> => {
    setStatusLoading(true);

    const response = await fetch(`${NYLAS_URL}/account`, {
      headers: new Headers({
        Authorization: token
      })
    }).then(res => res.json());

    setStatusLoading(false);

    return response;
  }, [token]);

  const connect = ({
    client_id,
    scope,
    email
  }: {
    client_id: string;
    scope: string;
    email: string;
  }) => {
    const params = new URLSearchParams({
      scope,
      client_id,
      response_type: 'token',
      redirect_uri: window.location.origin + ROUTES.SETTINGS_INTEGRATIONS,
      login_hint: email
    });

    window.location.href = `https://api.nylas.com/oauth/authorize?${params.toString()}`;
  };

  const connectV3 = (client_id: string) => {
    const params = new URLSearchParams({
      client_id,
      response_type: 'code',
      access_type: 'offline',
      redirect_uri: window.location.origin + ROUTES.SETTINGS_INTEGRATIONS
    });

    window.location.href = `https://api.us.nylas.com/v3/connect/auth?${params.toString()}`;
  };

  const getContacts = async (): Promise<any[]> =>
    new Promise(resolve => {
      const limitPerCall = 500;
      const contactsToLoad = 5000;
      let allContacts: any[] = [];

      const refetchContacts = (): Promise<any[] | void> =>
        fetch(
          `${NYLAS_URL}/contacts?limit=${limitPerCall}&offset=${allContacts.length}&source=inbox`,
          {
            headers: new Headers({
              Authorization: token
            })
          }
        )
          .then(res => res.json())
          .then((contacts: any[] | void) => {
            if (!Array.isArray(contacts)) return;
            allContacts = allContacts.concat(contacts);
            /*
             * Load maximum of 5000 contacts
             */
            if (contacts.length >= contactsToLoad) return resolve(allContacts);

            /*
             * Check if there is more contacts to fech
             */
            if (contacts.length === limitPerCall) return refetchContacts();

            resolve(allContacts);
          });

      refetchContacts();
    });

  return {
    statusLoading,

    connect,
    connectV3,
    getStatus,
    getContacts
  };
};
