import { IconButton, styled } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export const BackButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.primary.main,

  '&:hover': {
    background: theme.palette.primary.dark
  }
}));

const SBackButton = ({ onClick }: { onClick: () => void }) => (
  <BackButton onClick={onClick}>
    <KeyboardBackspaceIcon htmlColor="#fff" />
  </BackButton>
);

export default SBackButton;
