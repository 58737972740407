import { Box, Divider, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { distances } from '../../styles';
import { ScrollButtonComponent } from './scroll-button-component';

type paddingType = 'small' | 'base' | 'medium' | 'xmedium' | 'large';

const getFontSize = (padding: paddingType) => {
  const fontSize: any = {
    small: 12,
    xmedium: 16,
    large: 16
  };
  return fontSize[padding] || 14;
};

const useStyles = makeStyles<Theme, { padding: paddingType }>(theme => ({
  tab: {
    textTransform: 'none',
    color: '#171725',
    fontWeight: 500,
    fontSize: ({ padding }) => getFontSize(padding),
    padding: ({ padding }) => `${distances.medium}px ${distances[padding]}px`,
    lineHeight: '20px',
    width: 'max-content',
    minWidth: 'max-content'
  },
  activeTab: {
    // Temporary fix for MUI styles issue not being properly applied in MUI v5 on digital showroom project
    // requires MUI update to V5 and style refactoring (removing makeStyles)
    color: '#2196F3',
    fontWeight: 700
    // color: theme.palette.primary.main
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 2,
    '& > span': {
      width: '100%',
      // Temporary fix for MUI styles issue not being properly applied in MUI v5 on digital showroom project
      // requires MUI update to V5 and style refactoring (removing makeStyles)
      backgroundColor: '#2196F3'
      // backgroundColor: theme.palette.primary.main
    }
  },
  root: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)'
  }
}));

type Value = any;

export type STab = {
  label: string;
  value: Value;
};

export interface TabsProps {
  currentTab: Value;
  tabsList: STab[];
  topDivider?: boolean;
  divider?: boolean;
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  padding?: paddingType;
  scrollButtons?: 'auto' | 'on' | 'off' | 'desktop';
  handleTabChange: (value: Value) => void;
}

export const STabs = ({
  tabsList,
  currentTab = tabsList[0].value,
  topDivider = false,
  divider = true,
  padding = 'medium',
  variant = 'standard',
  scrollButtons = 'auto',
  handleTabChange
}: TabsProps) => {
  const classes = useStyles({ padding });

  return (
    <Box>
      {topDivider ? <Divider /> : null}
      <Tabs
        variant={variant}
        value={currentTab}
        scrollButtons={scrollButtons}
        classes={{
          indicator: classes.indicator,
          scrollButtons: classes.root
        }}
        ScrollButtonComponent={props => {
          if (
            props.disabled &&
            (scrollButtons === 'auto' || scrollButtons === 'on')
          )
            return null;
          return (
            <ScrollButtonComponent
              onClick={props.onClick}
              direction={props.direction}
            />
          );
        }}
        onChange={(_, value) => handleTabChange(value)}
        TabIndicatorProps={{ children: <span /> }}>
        {tabsList?.map(tab => (
          <Tab
            key={tab.label}
            value={tab.value}
            label={tab.label}
            className={clsx(classes.tab, {
              [classes.activeTab]: currentTab === tab.value
            })}
          />
        ))}
      </Tabs>
      {divider ? <Divider /> : null}
    </Box>
  );
};
