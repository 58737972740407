import { AxiosMethods, RFQApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

const body = {
  topK: 10,
  threshold: 0.5
};

const headers = {
  'Content-Type': 'application/json',
  Accept: 'text/plain'
};

export const getJobIdByUrl = () => ({
  mutationFn: (url: string) =>
    axiosHelper({
      endpoint: RFQApi.GetJobIdByUrl,
      method: AxiosMethods.POST,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers,
      body: {
        ...body,
        type: 'Standard',
        url
      }
    })
});

export const getJobIdByFile = () => ({
  mutationFn: (file: File) =>
    axiosHelper({
      endpoint: RFQApi.GetJobIdByFile,
      method: AxiosMethods.POST,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'multipart/form-data'
      },
      params: {
        ...body,
        type: 'Custom1'
      },
      body: {
        file
      }
    })
});

export const getProductsByUrl = () => ({
  mutationFn: (url: string) =>
    axiosHelper({
      endpoint: RFQApi.GetProductsByUrl,
      method: AxiosMethods.POST,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers,
      body: {
        url,
        type: 'Standard',
        ...body
      }
    })
});

export const getProductsByText = () => ({
  mutationFn: (text: string) =>
    axiosHelper({
      endpoint: RFQApi.GetProductsByText,
      method: AxiosMethods.POST,
      baseURL: process.env.INTELLIGENCE_API_BASE_URL,
      headers,
      body: {
        text,
        ...body
      }
    })
});
