import { useMemo } from 'react';
import { Box } from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import {
  hasAtLeastOneNumber,
  hasAtLeastOneUpperCase,
  hasAtLeastOneSpecialCharacter
} from '../../utils';
import SText from '../../../sui/stext';

enum COLORS {
  GREEN = '#23A566',
  BLACK = '#171725',
  DARK_GREY = '#696974',
  GREY = '#D5D5DC',
  LIGHT_GREY = '#B5B5BE',
  RED = '#FF0000'
}

const SPasswordInstructions = ({ password }: { password: string }) => {
  const instructions = useMemo(
    () => [
      {
        done: password.length >= 8,
        text: 'Minimum 8 characters in length'
      },
      {
        done: hasAtLeastOneNumber(password),
        text: 'At least one Number (0-9)'
      },
      {
        done: hasAtLeastOneUpperCase(password),
        text: 'At least one upper case letter (A-Z)'
      },
      {
        done: hasAtLeastOneSpecialCharacter(password),
        text: 'At least one special character ($#%&{}!)'
      }
    ],
    [password]
  );

  return (
    <Box display="flex" flexDirection="column" gridGap={12} maxWidth={450}>
      {instructions.map(({ done, text }) => (
        <Box key={text} display="flex" alignItems="center" gridGap={8}>
          {done ? (
            <CheckCircleRoundedIcon fontSize="small" htmlColor={COLORS.GREEN} />
          ) : (
            <CancelRoundedIcon fontSize="small" htmlColor={COLORS.RED} />
          )}
          <SText maxLines={2} title={text} fontColor={COLORS.BLACK} />
        </Box>
      ))}
    </Box>
  );
};

export default SPasswordInstructions;
