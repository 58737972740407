export enum BannersApi {
  Banners = '/WebAPI/GetBanners'
}

export enum AdminBannersApi {
  GetCounts = '/api/v2/admin/Banners/count',
  GetBanners = '/api/v2/admin/Banners',
  CreateBanner = '/api/v2/admin/Banners',
  EditBanner = '/api/v2/admin/Banners',
  GetBanner = '/api/v2/admin/Banners/:id/getBanner',
  DeleteBanners = '/api/v2/admin/Banners/batchDelete',
  ChangeBannersStatus = '/api/v2/admin/Banners/batchChangeStatus/:statusId',
  GetBannerGroups = '/api/v2/admin/Groups/getGroupsFromBanners',
  ChangeBannersOrder = '/api/v2/admin/Banners/changeOrder',
  GetDefaultBanners = '/api/v2/admin/Banners/getDefaultBanner',
  UpdateDefaultBanners = '/api/v2/admin/Banners/defaultBanner',
  UpdateBranding = '/api/v2/admin/Banners/logo',
  GetBranding = '/api/v2/admin/Banners/getLogo'
}
