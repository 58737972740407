import { AxiosMethods, ResourceApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';
import {
  QueryFunctionContext,
  UseInfiniteQueryOptions
} from '@tanstack/react-query';
import { ROUTES } from 'enumsV2';
import { Product } from '@setvi/shared/interfaces';
import {
  GetCompanyResourcesQuery,
  GetFavoriteResourcesQuery,
  GetFeaturedResourcesQuery,
  GetRecentlyUploadedResourcesQuery,
  GetUserResourcesQuery
} from './Types';

export enum ResourcesQueryKey {
  RESOURCE = 'resource',
  USER_RESOURCES = 'user_resources',
  COMPANY_RESOURCES = 'company_resources',
  FEATURED_RESOURCES = 'featured_resources',
  GET_SHAREABLE_LINK = 'get_shareable_link',
  FAVORITES_RESOURCES = 'favorites_resources',
  RECENTLY_UPLOADED_RESOURCES = 'recently_uploaded_resources',
  PRODUCT_ASSOCIATED_RESOURCES = 'product_associated_resources'
}

interface getCompanyResourcesQueryParams {
  pageSize: number;
  pageNumber: number;
}

export const getCompanyResourcesQuery = (
  categoryId: number,
  params: getCompanyResourcesQueryParams
): UseInfiniteQueryOptions<GetCompanyResourcesQuery> => ({
  queryKey: [ResourcesQueryKey.COMPANY_RESOURCES, categoryId, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ResourceApi.GetCompanyResources.replace(
        ':categoryId',
        categoryId?.toString()
      ),
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  select: (res: any) => res,
  getNextPageParam: (
    lastPage: GetCompanyResourcesQuery,
    allPages: GetCompanyResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Count > total ? currentPageNumber + 1 : undefined;
  },
  refetchOnWindowFocus: false,
  enabled: window.location.pathname.includes(ROUTES.RESOURCES)
});

interface getRecentlyUploadedRecourcesQueryParams {
  pageSize: number;
  pageNumber: number;
  orderBy: string;
  sortBy: string;
}

export const getRecentlyUploadedResourcesQuery = (
  params: getRecentlyUploadedRecourcesQueryParams
): UseInfiniteQueryOptions<GetRecentlyUploadedResourcesQuery> => ({
  queryKey: [ResourcesQueryKey.RECENTLY_UPLOADED_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ResourceApi.GetRecentlyUploaded,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetRecentlyUploadedResourcesQuery,
    allPages: GetRecentlyUploadedResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Resources?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Data?.Resources?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

interface getFeaturedResourcesQueryParams {
  pageSize: number;
  pageNumber: number;
  orderBy?: string;
  sortBy?: string;
}

export const featuredResourcesQuery = (
  params: getFeaturedResourcesQueryParams
): UseInfiniteQueryOptions<GetFeaturedResourcesQuery> => ({
  queryKey: [ResourcesQueryKey.FEATURED_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ResourceApi.GetFeatured,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetFeaturedResourcesQuery,
    allPages: GetFeaturedResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Resources?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;

    return lastPage?.Data?.Resources?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const favoritesResourcesQuery = (input?: any) => ({
  queryKey: [ResourcesQueryKey.FAVORITES_RESOURCES, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetFavorite,
      method: AxiosMethods.GET,
      params: data?.queryKey[1]
    }),
  select: (res: any) => res?.Data
});

interface getFavoriteResourcesQueryParams {
  pageNumber: number;
  pageSize: number;
  orderBy: string;
  sortBy: string;
}

// Infinite query implementation
export const getFavoriteResourcesQuery = (
  params: getFavoriteResourcesQueryParams
): UseInfiniteQueryOptions<GetFavoriteResourcesQuery> => ({
  queryKey: [ResourcesQueryKey.FAVORITES_RESOURCES, params],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ResourceApi.GetFavorite,
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetFavoriteResourcesQuery,
    allPages: GetFavoriteResourcesQuery[]
  ) => {
    const total = allPages?.reduce(
      (acc, cur) => acc + cur?.Data?.Result?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getShareableLinkQuery = (input?: any) => ({
  queryKey: [ResourcesQueryKey.GET_SHAREABLE_LINK, input],
  queryFn: (data: any) =>
    axiosHelper({
      endpoint: ResourceApi.GetShareableLink,
      method: AxiosMethods.POST,
      params: data?.queryKey[1]
    }),
  select: (res: any) => res?.Data[0] || null
});

interface getUserResourcesQueryParams {
  pageNumber: number;
  pageSize: number;
}

export const getUserResourcesQuery = (
  categoryId: number,
  params: getUserResourcesQueryParams
): UseInfiniteQueryOptions<GetUserResourcesQuery> => ({
  queryKey: [ResourcesQueryKey.USER_RESOURCES, params, categoryId],
  queryFn: (data: QueryFunctionContext) =>
    axiosHelper({
      endpoint: ResourceApi.GetUserResources.replace(
        ':categoryId',
        categoryId?.toString()
      ),
      method: AxiosMethods.GET,
      params: { ...params, pageNumber: data.pageParam || params.pageNumber }
    }),
  getNextPageParam: (
    lastPage: GetUserResourcesQuery,
    allpages: GetUserResourcesQuery[]
  ) => {
    const total = allpages?.reduce(
      (acc, curr) => acc + curr?.Data?.Result?.length,
      0
    );
    const currentPageNumber = total / params.pageSize;
    return lastPage?.Data?.Result?.length < params.pageSize
      ? undefined
      : currentPageNumber + 1;
  },
  refetchOnWindowFocus: false
});

export const getResource = (Id: string | number) => ({
  queryKey: [ResourcesQueryKey.RESOURCE, Id],
  queryFn: () =>
    axiosHelper({
      endpoint: ResourceApi.GetResource.replace(':resourceId', Id.toString()),
      method: AxiosMethods.GET
    }),
  cacheTime: 0,
  select: (res: any) => res,
  refetchOnWindowFocus: false
});

export const productAssociatedResources = (ProductId: Product['sku']) => ({
  queryKey: [ResourcesQueryKey.PRODUCT_ASSOCIATED_RESOURCES, ProductId],
  queryFn: () =>
    axiosHelper({
      endpoint: ResourceApi.ProductAssociatedResources,
      method: AxiosMethods.POST,
      data: {
        ProductId
      }
    }),
  select: (res: any) => res?.Data
});
