import { createTheme } from '@material-ui/core/styles';

// ** Theme Override Imports
import overrides from './overrides';

export const muiThemeOptions = createTheme({
  spacing: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  palette: {
    primary: {
      light: '#6FBBFD',
      main: '#1E96FC',
      dark: '#037AE2'
    },
    error: {
      main: '#FF0000',
      light: '#FF4D4D'
    },
    success: {
      main: '#23A566',
      light: '#3DD598'
    },
    warning: {
      main: '#FF9F29'
    },
    text: {
      primary: '#171725',
      secondary: '#44444F'
    },
    background: {
      default: '#FAFAFB'
    }
  },
  typography: {
    fontFamily:
      'Roboto, Helvetica, Arial, sans-serif, serif, Times, Monospace, Verdana, "Trebuchet MS", Georgia'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1366,
      xl: 1536
    }
  },
  mixins: {
    toolbar: {
      minHeight: 76
    }
  }
});

export const muiTheme = createTheme(muiThemeOptions, {
  overrides: { ...overrides(muiThemeOptions) }
});
