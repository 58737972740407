import { UseMutationOptions } from '@tanstack/react-query';
import { AxiosMethods, IntegrationApi, UsersApi } from '@setvi/shared/enums';
import { axiosHelper, CrmTypes } from '@setvi/shared/services';
import {
  GetNylasUserStatusMutation,
  SetSalesforceEmailMutation
} from './Types';

export enum UserMutationsKey {
  UPDATE_STATUS = 'update_status',
  CREATE_CAMPAIGN = 'create_campaign',
  UPDATE_CAMPAIGN = 'update_campaign'
}

export const sendVerificationMailMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: UsersApi.SendVerificationMail,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const updateNotificationsMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: UsersApi.UpdateNotificationSettings,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const updateSignatureMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: UsersApi.UpdateSignature,
      method: AxiosMethods.PUT,
      baseURL: process.env.ADMIN_API_BASE_URL,
      params,
      body
    })
});

export const resetPasswordMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: UsersApi.ResetUserPassword,
      method: AxiosMethods.POST,
      params,
      body
    })
});

interface setSalesforceEmailPayload {
  email: string;
  type: CrmTypes.User;
}

export const setSalesforceEmailMutation = () => ({
  mutationFn: (
    body: setSalesforceEmailPayload
  ): Promise<SetSalesforceEmailMutation> =>
    axiosHelper({
      endpoint: IntegrationApi.SetSalesforceEmail,
      method: AxiosMethods.POST,
      body
    })
});

interface setCrmBccEmailMutation {
  userCrmBccEmail: string;
}

export const setCrmBccEmailMutation = () => ({
  mutationFn: (body: setCrmBccEmailMutation): Promise<string> =>
    axiosHelper({
      endpoint: IntegrationApi.SetBccEmail,
      method: AxiosMethods.POST,
      body
    })
});

interface saveSalesforceUserMutationPayload {
  SalesforceEmail: string;
  SalesforceUserID: string;
}

export const saveSalesforceUserMutation = () => ({
  mutationFn: (body: saveSalesforceUserMutationPayload) =>
    axiosHelper({
      endpoint: IntegrationApi.CrmCredentials,
      method: AxiosMethods.PUT,
      body
    })
});

export const removeSalesforceEmailMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: IntegrationApi.CrmCredentials,
      method: AxiosMethods.DELETE,
      params,
      body
    })
});

export const setEmailServerTokenMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: IntegrationApi.SetEmailServerToken,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const getNylasAccountStatus =
  (): UseMutationOptions<GetNylasUserStatusMutation> => ({
    mutationFn: () =>
      axiosHelper({
        endpoint: IntegrationApi.GetNylasAccountStatus,
        method: AxiosMethods.GET
      })
  });

interface ConnectNylasAccountPayload {
  Code: string;
  RedirectUri: string;
}

export const connectNylasAccount = (): UseMutationOptions<
  GetNylasUserStatusMutation,
  unknown,
  ConnectNylasAccountPayload
> => ({
  mutationFn: ({ Code, RedirectUri }) =>
    axiosHelper({
      endpoint: IntegrationApi.ConnectNylasAccount,
      method: AxiosMethods.POST,
      body: { Code, RedirectUri }
    })
});

export const disconnectNylasAccount = (): UseMutationOptions => ({
  mutationFn: () =>
    axiosHelper({
      endpoint: IntegrationApi.DisconnectNylasAccount,
      method: AxiosMethods.POST
    })
});

export const removeEmailServerTokenMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: IntegrationApi.RemoveEmailServerToken,
      method: AxiosMethods.POST,
      params,
      body
    })
});

export const updateUserDetailsMutation = () => ({
  mutationFn: ({ body, params }: any) =>
    axiosHelper({
      endpoint: UsersApi.UpdateUserDetails,
      method: AxiosMethods.PUT,
      params,
      body
    })
});
